import React from 'react';
import { 
  Box, 
  Container, 
  Grid, 
  Typography, 
  Link as MuiLink,
  Button,
  Divider,
  useMediaQuery,
  IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

// Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // Get current year for copyright
  const currentYear = new Date().getFullYear();
  
  // Footer sections
  const sections = [
    {
      title: 'Product',
      links: [
        { name: 'Features', path: '/features' },
        { name: 'Pricing', path: '/pricing' },
        { name: 'Demo', path: '/demo' },
        { name: 'Car Color Changer', path: '/car-color-changer' },
      ],
    },
    {
      title: 'Company',
      links: [
        { name: 'About Us', path: '/about' },
        { name: 'Contact', path: '/contact' },
        { name: 'Privacy Policy', path: '/privacy' },
        { name: 'Terms of Service', path: '/terms' },
      ],
    },
    {
      title: 'Resources',
      links: [
        { name: 'Help Center', path: '/help' },
        { name: 'Blog', path: '/blog' },
        { name: 'Videos', path: '/videos' },
        { name: 'Support', path: '/support' },
      ],
    },
  ];
  
  // Social media links
  const socialLinks = [
    { icon: <FacebookIcon />, name: 'Facebook', url: 'https://facebook.com' },
    { icon: <TwitterIcon />, name: 'Twitter', url: 'https://twitter.com' },
    { icon: <InstagramIcon />, name: 'Instagram', url: 'https://instagram.com' },
    { icon: <LinkedInIcon />, name: 'LinkedIn', url: 'https://linkedin.com' },
  ];
  
  // Contact information
  const contactInfo = [
    { icon: <EmailIcon fontSize="small" />, text: 'support@reignovation.com' },
    { icon: <PhoneIcon fontSize="small" />, text: '' },
    { icon: <LocationOnIcon fontSize="small" />, text: 'Lexington Park, MD' },
  ];
  
  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        bgcolor: 'primary.dark',
        color: 'white',
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        {/* Main Footer Content */}
        <Grid container spacing={4} justifyContent="space-between">
          {/* Logo and Company Info */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <DirectionsCarIcon sx={{ fontSize: 32, mr: 1 }} />
              <Typography variant="h5" component="div" fontWeight="bold">
                VisionStudio
              </Typography>
            </Box>
            
            <Typography variant="body2" sx={{ mb: 3, opacity: 0.8 }}>
              Reignovation is a software consulting company specializing in creating innovative, 
              custom solutions for businesses across industries. We help companies streamline 
              operations and elevate their customer experiences with cutting-edge technology and tools.
            </Typography>
            
            {/* Contact Information */}
            <Box sx={{ mb: 3 }}>
              {contactInfo.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box sx={{ mr: 1, opacity: 0.8 }}>{item.icon}</Box>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    {item.text}
                  </Typography>
                </Box>
              ))}
            </Box>
            
            {/* Social Media Links */}
            <Box sx={{ display: 'flex', gap: 1 }}>
              {socialLinks.map((item, index) => (
                <IconButton
                  key={index}
                  component="a"
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{
                    color: 'white',
                    opacity: 0.8,
                    '&:hover': {
                      opacity: 1,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                  aria-label={item.name}
                >
                  {item.icon}
                </IconButton>
              ))}
            </Box>
          </Grid>
          
          {/* Footer Sections */}
          {!isMobile && sections.map((section) => (
            <Grid item xs={12} sm={6} md={2} key={section.title}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                {section.title}
              </Typography>
              <Box component="ul" sx={{ p: 0, m: 0, listStyle: 'none' }}>
                {section.links.map((link) => (
                  <Box component="li" key={link.name} sx={{ mb: 1 }}>
                    <MuiLink
                      component={RouterLink}
                      to={link.path}
                      underline="hover"
                      sx={{
                        color: 'white',
                        opacity: 0.8,
                        '&:hover': { opacity: 1 },
                        textDecoration: 'none',
                      }}
                    >
                      {link.name}
                    </MuiLink>
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
          
          {/* Mobile Accordion Version */}
          {isMobile && (
            <Grid item xs={12}>
              <Divider sx={{ my: 3, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
              <Grid container spacing={2}>
                {sections.map((section) => (
                  <Grid item xs={6} sm={4} key={section.title}>
                    <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                      {section.title}
                    </Typography>
                    <Box component="ul" sx={{ p: 0, m: 0, listStyle: 'none' }}>
                      {section.links.slice(0, 3).map((link) => (
                        <Box component="li" key={link.name} sx={{ mb: 1 }}>
                          <MuiLink
                            component={RouterLink}
                            to={link.path}
                            underline="hover"
                            sx={{
                              color: 'white',
                              opacity: 0.8,
                              '&:hover': { opacity: 1 },
                              fontSize: '0.875rem',
                              textDecoration: 'none',
                            }}
                          >
                            {link.name}
                          </MuiLink>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        
        {/* Bottom Bar */}
        <Box sx={{ mt: 6, pt: 3, borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" sx={{ opacity: 0.7, textAlign: { xs: 'center', sm: 'left' } }}>
                © {currentYear} Reignovation. All rights reserved.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: { xs: 2, sm: 0 } }}>
              <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                justifyContent: { xs: 'center', sm: 'flex-end' },
                flexWrap: 'wrap'
              }}>
                <MuiLink
                  component={RouterLink}
                  to="/privacy"
                  underline="hover"
                  sx={{ color: 'white', opacity: 0.7, fontSize: '0.875rem' }}
                >
                  Privacy Policy
                </MuiLink>
                <MuiLink
                  component={RouterLink}
                  to="/terms"
                  underline="hover"
                  sx={{ color: 'white', opacity: 0.7, fontSize: '0.875rem' }}
                >
                  Terms of Service
                </MuiLink>
                <MuiLink
                  component={RouterLink}
                  to="/cookies"
                  underline="hover"
                  sx={{ color: 'white', opacity: 0.7, fontSize: '0.875rem' }}
                >
                  Cookie Policy
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;