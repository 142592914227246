import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { 
    onAuthStateChanged, 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    signOut,
    updateProfile,
    sendPasswordResetEmail,
    updateEmail,
    updatePassword
} from 'firebase/auth';
import { auth, db } from '../../services/firebase';
import { 
    doc, 
    getDoc, 
    setDoc, 
    updateDoc,
    enableNetwork, 
    disableNetwork 
} from 'firebase/firestore';

// Create the context
const AuthContext = createContext();

// Custom hook to use the auth context
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    // Network status management
    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            enableNetwork(db).catch(console.error);
        };
        
        const handleOffline = () => {
            setIsOnline(false);
            disableNetwork(db).catch(console.error);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    // Fetch user profile function with retry logic, including the role
    const fetchUserProfile = useCallback(async (uid, retryCount = 3) => {
        if (!uid) return null;
        
        try {
            const userDocRef = doc(db, 'users', uid);
            const userDoc = await getDoc(userDocRef);
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setUserProfile(userData); // Include role in userProfile
                return userData;
            }
            return null;
        } catch (error) {
            console.error("Error fetching user profile:", error);
            if (retryCount > 0 && isOnline) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                return fetchUserProfile(uid, retryCount - 1);
            }
            setError(error.message);
            return null;
        }
    }, [isOnline]);

    // Signup function with default "User" role
    async function signup(email, password, displayName, userData) {
        try {
            // Create the user in Firebase Auth
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Update the user's display name
            await updateProfile(user, {
                displayName: displayName
            });

            // Create the user document in Firestore with a default "User" role
            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                email: user.email,
                displayName: displayName,
                role: 'User', // Default role
                ...userData,
                tier: 'free',
                createdAt: new Date(),
                lastLogin: new Date()
            });

            // Fetch the user profile
            await fetchUserProfile(user.uid);
            return user;
        } catch (error) {
            console.error("Signup error:", error);
            setError(error.message);
            throw error;
        }
    }

    // Login function
    async function login(email, password) {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Update last login time
            await updateDoc(doc(db, 'users', user.uid), {
                lastLogin: new Date()
            });

            // Fetch the user profile, including the role
            await fetchUserProfile(user.uid);
            return user;
        } catch (error) {
            console.error("Login error:", error);
            setError(error.message);
            throw error;
        }
    }

    // Logout function
    async function logout() {
        try {
            await signOut(auth);
            setUserProfile(null);
            setError(null);
        } catch (error) {
            console.error("Logout error:", error);
            setError(error.message);
            throw error;
        }
    }

    // Update user profile function
    async function updateUserProfile(updates) {
        if (!currentUser) throw new Error('No user logged in');

        try {
            const userRef = doc(db, 'users', currentUser.uid);
            await updateDoc(userRef, {
                ...updates,
                updatedAt: new Date()
            });

            // If display name is being updated, update it in Firebase Auth too
            if (updates.displayName) {
                await updateProfile(currentUser, {
                    displayName: updates.displayName
                });
            }

            // Fetch updated profile
            await fetchUserProfile(currentUser.uid);
        } catch (error) {
            console.error("Profile update error:", error);
            setError(error.message);
            throw error;
        }
    }

    // Update email function
    async function updateUserEmail(newEmail) {
        if (!currentUser) throw new Error('No user logged in');

        try {
            await updateEmail(currentUser, newEmail);
            await updateDoc(doc(db, 'users', currentUser.uid), {
                email: newEmail,
                updatedAt: new Date()
            });
            await fetchUserProfile(currentUser.uid);
        } catch (error) {
            console.error("Email update error:", error);
            setError(error.message);
            throw error;
        }
    }

    // Update password function
    async function updateUserPassword(newPassword) {
        if (!currentUser) throw new Error('No user logged in');

        try {
            await updatePassword(currentUser, newPassword);
        } catch (error) {
            console.error("Password update error:", error);
            setError(error.message);
            throw error;
        }
    }

    // Password reset function
    async function resetPassword(email) {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (error) {
            console.error("Password reset error:", error);
            setError(error.message);
            throw error;
        }
    }

    // Clear error function
    const clearError = () => setError(null);

    // Auth state listener
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);
            if (user) {
                await fetchUserProfile(user.uid); // Fetch profile, including role, on auth state change
            } else {
                setUserProfile(null);
            }
            setLoading(false);
        });

        return unsubscribe;
    }, [fetchUserProfile]);

    // Context value
    const value = {
        currentUser,
        userProfile,
        loading,
        error,
        isOnline,
        signup,
        login,
        logout,
        updateUserProfile,
        updateUserEmail,
        updateUserPassword,
        resetPassword,
        fetchUserProfile,
        clearError
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}