import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Tabs,
  Tab,
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  Collapse,
  Tooltip,
  useTheme,
  useMediaQuery,
  Zoom
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import ColorLensIcon from '@mui/icons-material/ColorLens';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineIcon from '@mui/icons-material/Timeline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Styled components
const ColorSwatch = styled(Box)(({ theme, selected }) => ({
  width: 36,
  height: 36,
  borderRadius: '50%',
  cursor: 'pointer',
  transition: 'all 0.15s ease-in-out',
  border: selected ? `3px solid ${theme.palette.primary.main}` : '1px solid rgba(0,0,0,0.12)',
  boxShadow: selected ? `0 0 0 2px ${theme.palette.background.paper}, 0 0 0 4px ${theme.palette.primary.main}` : 'none',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}, 0 2px 8px 0 rgba(0,0,0,0.2)`,
    zIndex: 1
  }
}));

const ColorPreview = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 80,
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'inset 0 0 8px rgba(0,0,0,0.15)',
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  transition: 'background-color 0.3s ease'
}));

/**
 * Color selection component for car body and rims
 * 
 * @param {Object} props
 * @param {string} props.selectedColor - Currently selected color name
 * @param {Function} props.setSelectedColor - Function to update selected color
 * @param {string} props.selectedFinish - Currently selected finish type
 * @param {Function} props.setSelectedFinish - Function to update selected finish
 * @param {boolean} props.enableRimColorChange - Whether rim color change is enabled
 * @param {Function} props.setEnableRimColorChange - Function to toggle rim color change
 * @param {string} props.selectedRimColor - Currently selected rim color name
 * @param {Function} props.setSelectedRimColor - Function to update selected rim color
 * @param {Object} props.colorLibrary - Color library object
 * @param {boolean} props.disabled - Whether component is disabled
 */
const ColorSelector = ({
  selectedColor,
  setSelectedColor,
  selectedFinish,
  setSelectedFinish,
  enableRimColorChange,
  setEnableRimColorChange,
  selectedRimColor,
  setSelectedRimColor,
  colorLibrary,
  disabled = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // State for tab indices
  const [bodyFinishTab, setBodyFinishTab] = useState(0);
  const [rimFinishTab, setRimFinishTab] = useState(0);
  
  // Array of finish types for conversion between index and name
  const finishTypes = ['gloss', 'satin', 'matte', 'premium'];
  
  // Update the body finish when tab changes
  useEffect(() => {
    setSelectedFinish(finishTypes[bodyFinishTab]);
  }, [bodyFinishTab, setSelectedFinish]);
  
  // Initialize the tab index based on selected finish
  useEffect(() => {
    const index = finishTypes.indexOf(selectedFinish);
    if (index !== -1) {
      setBodyFinishTab(index);
    }
  }, [selectedFinish]);
  
  // Handle body finish tab change
  const handleBodyFinishChange = (event, newValue) => {
    if (disabled) return;
    setBodyFinishTab(newValue);
  };
  
  // Handle rim finish tab change
  const handleRimFinishChange = (event, newValue) => {
    if (disabled) return;
    setRimFinishTab(newValue);
  };
  
  // Handle color selection
  const handleColorSelect = (color) => {
    if (disabled) return;
    setSelectedColor(color.name);
  };
  
  // Handle rim color selection
  const handleRimColorSelect = (color) => {
    if (disabled) return;
    setSelectedRimColor(color.name);
  };
  
  // Get the hex color for the current selected color
  const getSelectedColorHex = () => {
    const currentFinish = finishTypes[bodyFinishTab];
    const colorObj = colorLibrary[currentFinish]?.find(c => c.name === selectedColor);
    return colorObj?.color || '#FFFFFF';
  };
  
  // Get the hex color for the current selected rim color
  const getSelectedRimColorHex = () => {
    const currentFinish = finishTypes[rimFinishTab];
    const colorObj = colorLibrary[currentFinish]?.find(c => c.name === selectedRimColor);
    return colorObj?.color || '#FFFFFF';
  };
  
  return (
    <Box sx={{ mb: 3 }}>
      {/* Body Color Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" fontWeight="medium" sx={{ 
          mb: 1,
          display: 'flex',
          alignItems: 'center', 
          gap: 0.5
        }}>
          <FormatPaintIcon fontSize="small" />
          Body Color
        </Typography>
        
        {/* Color Preview */}
        <ColorPreview sx={{ 
          backgroundColor: getSelectedColorHex(),
          opacity: disabled ? 0.7 : 1
        }} />
        
        {/* Selected color info */}
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="body1" fontWeight="medium">
              {selectedColor || 'Select a color'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {finishTypes[bodyFinishTab].charAt(0).toUpperCase() + finishTypes[bodyFinishTab].slice(1)} Finish
            </Typography>
          </Box>
          <Box sx={{ 
            width: 24, 
            height: 24, 
            borderRadius: '50%', 
            bgcolor: getSelectedColorHex(),
            border: `1px solid ${theme.palette.divider}`
          }} />
        </Box>
        
        {/* Finish tabs */}
        <Paper 
          variant="outlined" 
          sx={{ mb: 2, borderRadius: theme.shape.borderRadius }}
        >
          <Tabs 
            value={bodyFinishTab} 
            onChange={handleBodyFinishChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            aria-label="Body finish type"
            disabled={disabled}
          >
            <Tab 
              label="Gloss" 
              disabled={disabled} 
              sx={{ 
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                minHeight: isMobile ? 36 : 48
              }} 
            />
            <Tab 
              label="Satin" 
              disabled={disabled} 
              sx={{ 
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                minHeight: isMobile ? 36 : 48
              }} 
            />
            <Tab 
              label="Matte" 
              disabled={disabled} 
              sx={{ 
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                minHeight: isMobile ? 36 : 48
              }} 
            />
            <Tab 
              label="Premium" 
              disabled={disabled} 
              sx={{ 
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                minHeight: isMobile ? 36 : 48
              }} 
            />
          </Tabs>
        </Paper>
        
        {/* Color swatches grid */}
        <Grid container spacing={1} sx={{ mb: 2 }}>
          {colorLibrary[finishTypes[bodyFinishTab]]?.map((color) => (
            <Grid item key={color.name}>
              <Tooltip title={color.name} arrow>
                <Zoom in={true}>
                  <ColorSwatch
                    sx={{ backgroundColor: color.color }}
                    selected={selectedColor === color.name}
                    onClick={() => handleColorSelect(color)}
                  >
                    {selectedColor === color.name && (
                      <Box sx={{ 
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <CheckCircleIcon 
                          sx={{ 
                            fontSize: 16, 
                            color: 'white',
                            filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.5))'
                          }} 
                        />
                      </Box>
                    )}
                  </ColorSwatch>
                </Zoom>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
      
      {/* Rim Color Toggle */}
      <Divider sx={{ my: 2 }} />
      <FormControlLabel
        control={
          <Checkbox
            checked={enableRimColorChange}
            onChange={(e) => setEnableRimColorChange(e.target.checked)}
            disabled={disabled}
            color="primary"
            size="small"
          />
        }
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <TimelineIcon sx={{ fontSize: '1rem' }} />
            <Typography variant="body2">Change Rim Color Separately</Typography>
          </Box>
        }
      />
      
      {/* Rim Color Section (conditional) */}
      <Collapse in={enableRimColorChange}>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" fontWeight="medium" sx={{ 
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}>
            <ColorLensIcon fontSize="small" />
            Rim Color
          </Typography>
          
          {/* Rim Color Preview */}
          <ColorPreview sx={{ 
            backgroundColor: getSelectedRimColorHex(),
            opacity: disabled ? 0.7 : 1
          }} />
          
          {/* Selected rim color info */}
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="body1" fontWeight="medium">
                {selectedRimColor || 'Select a color'}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {finishTypes[rimFinishTab].charAt(0).toUpperCase() + finishTypes[rimFinishTab].slice(1)} Finish
              </Typography>
            </Box>
            <Box sx={{ 
              width: 24, 
              height: 24, 
              borderRadius: '50%', 
              bgcolor: getSelectedRimColorHex(),
              border: `1px solid ${theme.palette.divider}`
            }} />
          </Box>
          
          {/* Rim finish tabs */}
          <Paper 
            variant="outlined" 
            sx={{ mb: 2, borderRadius: theme.shape.borderRadius }}
          >
            <Tabs 
              value={rimFinishTab} 
              onChange={handleRimFinishChange}
              variant="fullWidth"
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="Rim finish type"
              disabled={disabled}
            >
              <Tab 
                label="Gloss" 
                disabled={disabled} 
                sx={{ 
                  fontSize: isMobile ? '0.75rem' : '0.875rem',
                  minHeight: isMobile ? 36 : 48
                }} 
              />
              <Tab 
                label="Satin" 
                disabled={disabled} 
                sx={{ 
                  fontSize: isMobile ? '0.75rem' : '0.875rem',
                  minHeight: isMobile ? 36 : 48
                }} 
              />
              <Tab 
                label="Matte" 
                disabled={disabled} 
                sx={{ 
                  fontSize: isMobile ? '0.75rem' : '0.875rem',
                  minHeight: isMobile ? 36 : 48
                }} 
              />
              <Tab 
                label="Premium" 
                disabled={disabled} 
                sx={{ 
                  fontSize: isMobile ? '0.75rem' : '0.875rem',
                  minHeight: isMobile ? 36 : 48
                }} 
              />
            </Tabs>
          </Paper>
          
          {/* Rim color swatches grid */}
          <Grid container spacing={1}>
            {colorLibrary[finishTypes[rimFinishTab]]?.map((color) => (
              <Grid item key={color.name}>
                <Tooltip title={color.name} arrow>
                  <Zoom in={true}>
                    <ColorSwatch
                      sx={{ backgroundColor: color.color }}
                      selected={selectedRimColor === color.name}
                      onClick={() => handleRimColorSelect(color)}
                    >
                      {selectedRimColor === color.name && (
                        <Box sx={{ 
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          <CheckCircleIcon 
                            sx={{ 
                              fontSize: 16, 
                              color: 'white',
                              filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.5))'
                            }} 
                          />
                        </Box>
                      )}
                    </ColorSwatch>
                  </Zoom>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ColorSelector;