import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Grid, 
  Typography, 
  Tabs, 
  Tab, 
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Slider,
  TextField,
  Alert,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Collapse,
  Fade,
  Zoom,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// Components
import ImageUpload from './ImageUpload';
import ColorSelector from './ColorSelector';
import ResultViewer from './ResultViewer';
import AdvancedSettings from './AdvancedSettings';
import APILog from './APILog';
import SaveDialog from './SaveDialog';
import LoadingOverlay from '../../common/LoadingOverlay';

// Icons
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SaveIcon from '@mui/icons-material/Save';
import PaletteIcon from '@mui/icons-material/Palette';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import TuneIcon from '@mui/icons-material/Tune';
import ErrorIcon from '@mui/icons-material/Error';
import PsychologyIcon from '@mui/icons-material/Psychology';

// Auth & Services
import { useAuth } from '../../auth/AuthContext';
import { uploadFile } from '../../../services/firebase';
import { callStabilityAPI } from '../../../services/stabilityService';

// Utils
import { colorLibrary } from '../../../utils/colorLibrary';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  border: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden'
}));

const TabPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto'
}));

const CarColorChanger = ({ onImageGenerated, usageStats, userProfile }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  
  // Tab state
  const [currentTab, setCurrentTab] = useState(0);
  
  // Image states
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  
  // Color selection states
  const [selectedColor, setSelectedColor] = useState('Black');
  const [selectedFinish, setSelectedFinish] = useState('gloss');
  const [enableRimColorChange, setEnableRimColorChange] = useState(false);
  const [selectedRimColor, setSelectedRimColor] = useState('Silver');
  
  // Advanced settings
  const [useAdvancedMode, setUseAdvancedMode] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('Transform ONLY the main car body. Preserve ALL original wheel and rim colors exactly as they appear.');
  const [growMaskValue, setGrowMaskValue] = useState(3);
  
  // UI states
  const [loading, setLoading] = useState(false);
  const [apiLog, setApiLog] = useState([]);
  const [error, setError] = useState(null);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');
  
  // Default selection prompts
  const selectionPrompts = [
    "Transform ONLY the main car body. Preserve ALL original wheel and rim colors exactly as they appear.",
    "Change ONLY the car body panels to a matte finish. DO NOT change wheel/rim colors.",
    "Only color the car body panels and exterior paint surfaces. Keep wheels and rims exactly as they are.",
    "Only modify the vehicle body paint. All wheels, rims, and tires must remain unchanged."
  ];
  
  // Selection prompts for when rim color change is enabled
  const rimSelectionPrompts = [
    "Change both the car body color AND the wheel/rim color separately.",
    "Modify both the vehicle body paint and the wheel rims to different colors.",
    "Apply new colors to both the car exterior body and the wheel rims."
  ];
  
  // Update custom prompt based on rim color toggle
  useEffect(() => {
    if (enableRimColorChange) {
      setCustomPrompt(rimSelectionPrompts[0]);
    } else {
      setCustomPrompt(selectionPrompts[0]);
    }
  }, [enableRimColorChange]);
  
  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  
  // Handle image upload
  const handleImageUpload = (imageFile) => {
    // Guard against null/undefined files and prevent processing during loading
    if (!imageFile || loading) return;
    
    // Use a local loading flag to prevent multiple uploads during the process
    setLoading(true);
    
    // Store the file for later processing
    setUploadedImageFile(imageFile);
    
    // Reset any previous states before processing the new image
    setResultImage(null);
    setError(null);
    
    // Create a FileReader to convert the file to a data URL
    const reader = new FileReader();
    
    reader.onload = (e) => {
      // Get the data URL
      const dataUrl = e.target.result;
      
      // Update the UI with the data URL - this should be done in one batch
      setUploadedImage(dataUrl);
      
      // Create a fresh log rather than appending to potentially stale state
      setApiLog([{
        type: 'info',
        timestamp: new Date().toLocaleTimeString(),
        message: `Loaded image: ${imageFile.name} (${Math.round(imageFile.size / 1024)} KB)`
      }]);
      
      // Switch to the original image tab
      setCurrentTab(0);
      
      // Release the loading state
      setLoading(false);
    };
    
    reader.onerror = () => {
      console.error('Error reading file:', reader.error);
      setError('Failed to read the image file. Please try again.');
      setLoading(false);
    };
    
    // Start reading the file
    reader.readAsDataURL(imageFile);
  };
  
  // Process the image with Stability AI
  const processImage = async () => {
    // Check if user has reached their limit
    if (usageStats.used >= usageStats.limit) {
      setError('You have reached your generation limit. Please upgrade your plan to continue.');
      navigate('/pricing');
      return;
    }
    
    if (!uploadedImageFile || !selectedColor) {
      setError('Please upload an image and select a color.');
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      setProcessingMessage('Preparing your image...');
      
      // Log the parameters
      const logEntry = {
        type: 'info',
        timestamp: new Date().toLocaleTimeString(),
        message: '== Processing Parameters ==\n' +
          `• Body Color: ${selectedColor}\n` +
          `• Finish: ${selectedFinish}\n` +
          (enableRimColorChange ? `• Rim Color: ${selectedRimColor}\n` : '') +
          `• Grow Mask: ${growMaskValue}\n` +
          `• Method: search_and_recolor`
      };
      
      setApiLog(prev => [...prev, logEntry]);
      setCurrentTab(2); // Switch to API log tab
      
      // Upload the image to Firebase Storage
      const timestamp = Date.now();
      const imagePath = `car_color_uploads/${currentUser.uid}/${timestamp}_${uploadedImageFile.name}`;
      
      setProcessingMessage('Uploading image...');
      const imageUrl = await uploadFile(uploadedImageFile, imagePath, {
        userId: currentUser.uid,
        timestamp: timestamp.toString()
      });
      
      setApiLog(prev => [...prev, {
        type: 'info',
        timestamp: new Date().toLocaleTimeString(),
        message: 'Image uploaded successfully'
      }]);
      
      // Prepare the API call parameters
      const colorData = {
        selectedColor: selectedColor,
        selectedFinish: selectedFinish,
        enableRimColorChange: enableRimColorChange,
        selectedRimColor: enableRimColorChange ? selectedRimColor : null,
        apiMethod: 'search_and_recolor',
        customPrompt: useAdvancedMode ? customPrompt : 
          (enableRimColorChange ? rimSelectionPrompts[0] : selectionPrompts[0]),
        growMaskValue: growMaskValue
      };
      
      setProcessingMessage(`Changing car to ${selectedColor}${enableRimColorChange ? ` with ${selectedRimColor} rims` : ''}...`);
      
      // Call the Stability API
      const result = await callStabilityAPI(imageUrl, colorData);
      
      if (!result.success) {
        throw new Error(result.error || 'Failed to process image');
      }
      
      // Log success
      setApiLog(prev => [...prev, {
        type: 'success',
        timestamp: new Date().toLocaleTimeString(),
        message: 'Image processed successfully'
      }]);
      
      // Store the result image
      setResultImage(result.resultImageUrl);
      
      // Notify parent component about successful generation
      onImageGenerated({
        selectedColors: {
          body: selectedColor,
          finish: selectedFinish,
          rims: enableRimColorChange ? selectedRimColor : null
        },
        originalImageUrl: imageUrl,
        resultImageUrl: result.resultImageUrl
      });
      
      // Switch to result tab
      setCurrentTab(1);
      
    } catch (err) {
      console.error('Error processing image:', err);
      setError(err.message || 'An error occurred while processing the image');
      
      setApiLog(prev => [...prev, {
        type: 'error',
        timestamp: new Date().toLocaleTimeString(),
        message: `Error: ${err.message || 'Unknown error'}`
      }]);
      
    } finally {
      setProcessingMessage('');
      setLoading(false);
    }
  };
  
  // Try again with different settings
  const retryWithDifferentSettings = () => {
    if (useAdvancedMode) {
      // Cycle through different selection prompts
      const currentPrompt = customPrompt;
      let prompts = enableRimColorChange ? rimSelectionPrompts : selectionPrompts;
      
      // Find the next prompt in the list
      try {
        const idx = prompts.indexOf(currentPrompt);
        const nextPrompt = prompts[(idx + 1) % prompts.length];
        setCustomPrompt(nextPrompt);
      } catch (err) {
        setCustomPrompt(prompts[0]);
      }
    } else {
      // Try with a different grow mask value
      const currentValue = growMaskValue;
      if (currentValue < 10) {
        setGrowMaskValue(currentValue + 2);
      } else {
        setGrowMaskValue(Math.max(0, currentValue - 5));
      }
    }
    
    // Process with new settings
    processImage();
  };
  
  // Handle save result
  const handleSaveResult = () => {
    if (resultImage) {
      setShowSaveDialog(true);
    }
  };
  
  return (
    <Grid container spacing={3} sx={{ minHeight: '70vh' }}>
      {/* Left panel - Controls */}
      <Grid item xs={12} md={4}>
        <StyledPaper>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 3,
            pb: 2,
            borderBottom: `1px solid ${theme.palette.divider}`
          }}>
            <PaletteIcon sx={{ color: 'primary.main', mr: 1 }} />
            <Typography variant="h6" component="h2" fontWeight="medium">
              Design Controls
            </Typography>
          </Box>
          
          {/* Image Upload */}
          <ImageUpload 
            onImageUpload={handleImageUpload} 
            uploadedImage={uploadedImage}
            disabled={loading}
          />
          
          {/* Color Selection */}
          <ColorSelector
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            selectedFinish={selectedFinish}
            setSelectedFinish={setSelectedFinish}
            enableRimColorChange={enableRimColorChange}
            setEnableRimColorChange={setEnableRimColorChange}
            selectedRimColor={selectedRimColor}
            setSelectedRimColor={setSelectedRimColor}
            colorLibrary={colorLibrary}
            disabled={loading}
          />
          
          {/* Advanced Settings Toggle */}
          <Box sx={{ mt: 2, mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={useAdvancedMode}
                  onChange={(e) => setUseAdvancedMode(e.target.checked)}
                  disabled={loading}
                  size="small"
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TuneIcon sx={{ mr: 0.5, fontSize: '1rem', color: 'text.secondary' }} />
                  <Typography variant="body2">Advanced Settings</Typography>
                </Box>
              }
            />
          </Box>
          
          {/* Advanced Settings */}
          <Collapse in={useAdvancedMode}>
            <AdvancedSettings
              customPrompt={customPrompt}
              setCustomPrompt={setCustomPrompt}
              growMaskValue={growMaskValue}
              setGrowMaskValue={setGrowMaskValue}
              disabled={loading}
            />
          </Collapse>
          
          {/* Standard Grow Mask Slider (only if advanced mode is off) */}
          {!useAdvancedMode && (
            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom variant="body2" sx={{ 
                display: 'flex', 
                alignItems: 'center',
                color: 'text.secondary',
                gap: 0.5
              }}>
                <TuneIcon fontSize="small" />
                Edge Blending
                <Tooltip title="Higher values create smoother color transitions but may affect details" arrow>
                  <InfoIcon fontSize="small" sx={{ ml: 0.5, fontSize: '1rem', color: 'text.secondary' }} />
                </Tooltip>
              </Typography>
              <Slider
                value={growMaskValue}
                onChange={(e, newValue) => setGrowMaskValue(newValue)}
                min={0}
                max={20}
                step={1}
                marks={[
                  { value: 0, label: '0' },
                  { value: 10, label: '10' },
                  { value: 20, label: '20' }
                ]}
                disabled={loading}
                size="small"
              />
            </Box>
          )}
          
          {/* Action Buttons */}
          <Box sx={{ mt: 'auto', pt: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
            {error && (
              <Alert 
                severity="error" 
                sx={{ mb: 2 }}
                variant="outlined"
                icon={<ErrorIcon />}
              >
                {error}
              </Alert>
            )}
            
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={processImage}
              disabled={!uploadedImage || loading || usageStats.used >= usageStats.limit}
              startIcon={loading ? <CircularProgress size={20} /> : <ColorLensIcon />}
              sx={{ mb: 2, py: 1.5 }}
            >
              {loading ? 'Processing...' : 'Change Car Color'}
            </Button>
            
            {resultImage && (
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                size="large"
                onClick={retryWithDifferentSettings}
                disabled={loading || usageStats.used >= usageStats.limit}
                startIcon={<PsychologyIcon />}
                sx={{ mb: 2 }}
              >
                Try Different Settings
              </Button>
            )}
            
            {resultImage && (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<SaveIcon />}
                onClick={handleSaveResult}
                disabled={loading}
              >
                Save Result
              </Button>
            )}
          </Box>
        </StyledPaper>
      </Grid>
      
      {/* Right panel - Results */}
      <Grid item xs={12} md={8}>
        <StyledPaper>
          {/* Tabs */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={currentTab} 
              onChange={handleTabChange} 
              aria-label="car image tabs"
              variant={isMobile ? "fullWidth" : "standard"}
            >
              <Tab 
                label="Original" 
                id="tab-0" 
                icon={<FileUploadIcon />} 
                iconPosition="start"
                sx={{ minHeight: 48 }}
              />
              <Tab 
                label="Result" 
                id="tab-1" 
                disabled={!resultImage} 
                icon={<ColorLensIcon />}
                iconPosition="start"
                sx={{ minHeight: 48 }}
              />
              <Tab 
                label="API Log" 
                id="tab-2" 
                icon={<InfoIcon />}
                iconPosition="start"
                sx={{ minHeight: 48 }}
              />
            </Tabs>
          </Box>
          
          {/* Tab Panels */}
          <Box sx={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
            <Fade in={currentTab === 0} style={{ display: currentTab === 0 ? 'flex' : 'none' }}>
              <TabPanel>
                <ResultViewer
                  image={uploadedImage}
                  title="Original Image"
                  emptyMessage="Upload an image to see it here"
                />
              </TabPanel>
            </Fade>
            
            <Fade in={currentTab === 1} style={{ display: currentTab === 1 ? 'flex' : 'none' }}>
              <TabPanel>
                <ResultViewer
                  image={resultImage}
                  title="Result Image"
                  emptyMessage="Process an image to see the result"
                />
              </TabPanel>
            </Fade>
            
            <Fade in={currentTab === 2} style={{ display: currentTab === 2 ? 'flex' : 'none' }}>
              <TabPanel>
                <APILog logEntries={apiLog} />
              </TabPanel>
            </Fade>
          </Box>
        </StyledPaper>
      </Grid>
      
      {/* Save dialog */}
      {showSaveDialog && (
        <SaveDialog
          open={showSaveDialog}
          onClose={() => setShowSaveDialog(false)}
          resultImage={resultImage}
        />
      )}
      
      {/* Loading overlay */}
      {loading && (
        <LoadingOverlay message={processingMessage} />
      )}
    </Grid>
  );
};

export default CarColorChanger;