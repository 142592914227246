import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Alert, 
  AlertTitle,
  Button, 
  Divider,
  Grid,
  useTheme,
  useMediaQuery,
  LinearProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Components
import PageContainer from '../layout/PageContainer';
import CarColorChanger from '../features/CarColorChanger/CarColorChanger';

// Utils and Services
import { useAuth } from '../auth/AuthContext';
import { 
  fetchCarColorChangerStats, 
  recordCarColorChange 
} from '../../services/firebase';

// Icons
import ColorLensIcon from '@mui/icons-material/ColorLens';
import TimerIcon from '@mui/icons-material/Timer';
import InfoIcon from '@mui/icons-material/Info';

const CarColorChangerPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();
  
  const [usageStats, setUsageStats] = useState({ used: 0, limit: 5 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Fetch user's usage stats when component mounts
  useEffect(() => {
    const fetchStats = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        const stats = await fetchCarColorChangerStats(currentUser.uid);
        
        // If user is paid, set limit to 500
        if (userProfile?.role === 'Paid') {
          stats.limit = 500; // paid user max 500 generations
        } else {
          stats.limit = 5; // demo user max 5 generations
        }
        
        setUsageStats(stats);
      } catch (err) {
        console.error('Error fetching usage stats:', err);
        setError('Failed to load your usage information. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchStats();
  }, [currentUser, userProfile]);
  
  // Handle successful image generation
  const handleImageGenerated = async (imageData) => {
    if (!currentUser) return;
    
    try {
      // Record the generation in Firestore
      await recordCarColorChange(currentUser.uid, imageData);
      
      // Update local state
      setUsageStats(prev => ({
        ...prev,
        used: prev.used + 1
      }));
    } catch (err) {
      console.error('Error recording generation:', err);
      // Don't block user experience even if recording fails
    }
  };
  
  // Calculate usage percentage
  const usagePercentage = (usageStats.used / usageStats.limit) * 100;
  const remainingGenerations = usageStats.limit - usageStats.used;
  
  return (
    <PageContainer>
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            fontWeight: 'bold', 
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <ColorLensIcon fontSize="large" color="primary" />
          Car Color Changer
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 3, maxWidth: 800 }}>
          Visualize your car with different colors using AI. Simply upload an image of your vehicle and 
          transform its appearance with our advanced color changing technology.
        </Typography>
        
        {/* User Account Status */}
        <Paper 
          elevation={0} 
          sx={{ 
            p: 2, 
            bgcolor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.50',
            borderRadius: 2,
            border: `1px solid ${theme.palette.divider}`,
            mb: 4
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={7}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle1" fontWeight="medium">
                  Your Account: {userProfile?.role === 'Paid' ? 'Premium' : 'Free Trial'}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <TimerIcon fontSize="small" />
                  {userProfile?.role === 'Paid' 
                    ? 'Unlimited access until your subscription ends'
                    : 'Your free trial includes 5 car color generations'}
                </Typography>
              </Box>
              
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" fontWeight="medium">
                  {usageStats.used} of {usageStats.limit} generations used
                </Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={Math.min(usagePercentage, 100)} 
                  sx={{ 
                    height: 8, 
                    borderRadius: 4, 
                    my: 1,
                    bgcolor: theme.palette.grey[300],
                    '& .MuiLinearProgress-bar': {
                      bgcolor: usagePercentage > 80 
                        ? theme.palette.error.main 
                        : theme.palette.primary.main
                    }
                  }}
                />
              </Box>
            </Grid>
            
            <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
              {remainingGenerations <= 0 ? (
                <Button 
                  variant="contained" 
                  color="primary"
                  size="large"
                  onClick={() => navigate('/pricing')}
                >
                  Upgrade to Premium
                </Button>
              ) : userProfile?.role !== 'Paid' && remainingGenerations <= 2 && (
                <Button 
                  variant="outlined" 
                  color="primary"
                  onClick={() => navigate('/pricing')}
                >
                  Get Unlimited Access
                </Button>
              )}
            </Grid>
          </Grid>
          
          {/* Usage Warning */}
          {remainingGenerations <= 0 && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <AlertTitle>Generation Limit Reached</AlertTitle>
              You've used all your available generations. Upgrade to Premium for unlimited access.
            </Alert>
          )}
        </Paper>
        
        {/* Information Alert */}
        <Alert 
          severity="info" 
          icon={<InfoIcon />}
          sx={{ 
            mb: 4,
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <Box>
            <AlertTitle>For Best Results</AlertTitle>
            <Typography variant="body2">
              • Upload a clear image of your car from the front, side, or 3/4 angle
            </Typography>
            <Typography variant="body2">
              • Avoid images with strong reflections, shadows, or poor lighting
            </Typography>
            <Typography variant="body2">
              • Higher resolution images will produce better results
            </Typography>
          </Box>
        </Alert>
      </Box>
      
      {/* Main Color Changer Component */}
      <Box sx={{ mb: 6 }}>
        <CarColorChanger 
          onImageGenerated={handleImageGenerated}
          usageStats={usageStats}
          userProfile={userProfile}
        />
      </Box>
      
      {/* FAQ Section */}
      <Box sx={{ mt: 8 }}>
        <Divider sx={{ mb: 4 }} />
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          Frequently Asked Questions
        </Typography>
        
        <Grid container spacing={3}>
          {[
            {
              question: "How does the Car Color Changer work?",
              answer: "Our Car Color Changer uses advanced AI to analyze your car's image and apply realistic color changes while preserving details like reflections and surface textures. The technology identifies the car's body panels and transforms the color while maintaining the original lighting conditions and environment."
            },
            {
              question: "What image formats are supported?",
              answer: "We support JPEG, PNG, and WebP image formats. For best results, use high-resolution images with good lighting and minimal reflections or shadows."
            },
            {
              question: "Can I change both the car body and rim colors?",
              answer: "Yes! You can change the car body color independently, or enable the rim color option to customize both the body and rims with different colors."
            },
            {
              question: "How accurate are the color previews?",
              answer: "Our AI generates highly realistic color changes that account for lighting, reflections, and the vehicle's surface properties. While the results are very accurate, slight variations may occur depending on the original image quality and lighting conditions."
            },
          ].map((faq, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  height: '100%',
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 2,
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {faq.question}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {faq.answer}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default CarColorChangerPage;