import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton, 
  Menu, 
  MenuItem, 
  Container, 
  Box, 
  Avatar, 
  Tooltip, 
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import FeaturesIcon from '@mui/icons-material/Stars';
import PricingIcon from '@mui/icons-material/AttachMoney';
import ContactIcon from '@mui/icons-material/ContactMail';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import DemoIcon from '@mui/icons-material/PlayArrow';
import ConfiguratorIcon from '@mui/icons-material/ViewInAr';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Auth Context
import { useAuth } from '../auth/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../services/firebase';

const NavigationBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();

  // Determine if current path is active
  const isActive = (path) => location.pathname === path;

  // Handle user menu
  const handleOpenUserMenu = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserMenuAnchor(null);
  };

  // Handle drawer
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Handle sign out
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      handleCloseUserMenu();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Get user initial for avatar
  const getUserInitial = () => {
    if (currentUser && currentUser.email) {
      return currentUser.email.charAt(0).toUpperCase();
    }
    return 'U';
  };

  // Navigation items - public
  const publicNavItems = [
    { name: 'Home', path: '/', icon: <HomeIcon /> },
    { name: 'Features', path: '/features', icon: <FeaturesIcon /> },
    { name: 'Pricing', path: '/pricing', icon: <PricingIcon /> },
    { name: 'Contact', path: '/contact', icon: <ContactIcon /> },
  ];

  // Navigation items - authenticated
  const getAuthNavItems = () => {
    const items = [
      { name: 'Demo', path: '/demo', icon: <DemoIcon /> },
      { name: 'AutoWrap', path: '/car-color-changer', icon: <ColorLensIcon /> },
    ];

    // Add configurator only for paid users
    if (userProfile?.role === 'Paid') {
      items.push({ name: 'Configurator', path: '/configurator', icon: <ConfiguratorIcon /> });
    }

    return items;
  };

  // User menu items
  const userMenuItems = [
    { name: 'Account', path: '/account', icon: <SettingsIcon /> },
    { name: 'Sign Out', action: handleSignOut, icon: <LogoutIcon /> },
  ];

  // Navigation drawer content
  const drawerContent = (
    <Box
      sx={{ width: 270 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <DirectionsCarIcon color="primary" />
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          VisionStudio
        </Typography>
      </Box>
      
      <Divider />
      
      <List>
        {publicNavItems.map((item) => (
          <ListItem 
            button 
            component={Link} 
            to={item.path} 
            key={item.name}
            selected={isActive(item.path)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
            {isActive(item.path) && <ChevronRightIcon color="primary" />}
          </ListItem>
        ))}
      </List>
      
      {currentUser && (
        <>
          <Divider />
          <Box sx={{ px: 2, py: 1 }}>
            <Typography variant="overline" color="text.secondary">
              My Account
            </Typography>
          </Box>
          
          <List>
            {getAuthNavItems().map((item) => (
              <ListItem 
                button 
                component={Link} 
                to={item.path} 
                key={item.name}
                selected={isActive(item.path)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
                {isActive(item.path) && <ChevronRightIcon color="primary" />}
              </ListItem>
            ))}
            
            <Divider sx={{ my: 1 }} />
            
            {userMenuItems.map((item) => (
              <ListItem 
                button 
                key={item.name}
                component={item.path ? Link : 'div'}
                to={item.path}
                onClick={item.action ? item.action : null}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
          
          <Box sx={{ p: 2 }}>
            <Typography variant="caption" color="text.secondary">
              {userProfile?.role === 'Paid' ? 'Premium Account' : 'Free Account'}
            </Typography>
          </Box>
        </>
      )}
      
      {!currentUser && (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Button 
            component={Link} 
            to="/login" 
            variant="outlined" 
            fullWidth
          >
            Log In
          </Button>
          <Button 
            component={Link} 
            to="/signup" 
            variant="contained" 
            fullWidth
          >
            Sign Up
          </Button>
        </Box>
      )}
    </Box>
  );

  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo - Desktop */}
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              fontWeight: 'bold',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <DirectionsCarIcon sx={{ mr: 1 }} />
            VisionStudio
          </Typography>

          {/* Mobile Menu */}
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                {drawerContent}
              </Drawer>
              
              {/* Logo - Mobile */}
              <Typography
                variant="h6"
                noWrap
                component={Link}
                to="/"
                sx={{
                  flexGrow: 1,
                  fontWeight: 'bold',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                VisionStudio
              </Typography>
            </>
          ) : (
            <>
              {/* Desktop Navigation */}
              <Box sx={{ flexGrow: 1, display: 'flex', ml: 2 }}>
                {publicNavItems.map((item) => (
                  <Button
                    key={item.name}
                    component={Link}
                    to={item.path}
                    sx={{ 
                      mx: 1, 
                      color: 'inherit',
                      position: 'relative',
                      '&::after': isActive(item.path) ? {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '3px',
                        backgroundColor: 'primary.main',
                        borderRadius: '3px 3px 0 0',
                      } : {},
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
                
                {/* Authenticated Navigation Items */}
                {currentUser && getAuthNavItems().map((item) => (
                  <Button
                    key={item.name}
                    component={Link}
                    to={item.path}
                    sx={{ 
                      mx: 1, 
                      color: 'inherit',
                      position: 'relative',
                      '&::after': isActive(item.path) ? {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '3px',
                        backgroundColor: 'primary.main',
                        borderRadius: '3px 3px 0 0',
                      } : {},
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            </>
          )}

          {/* User Authentication */}
          <Box sx={{ flexGrow: 0, ml: 2 }}>
            {currentUser ? (
              <>
                <Tooltip title="Account settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={userProfile?.role !== 'Paid'}
                      overlap="circular"
                    >
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        {getUserInitial()}
                      </Avatar>
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="user-menu"
                  anchorEl={userMenuAnchor}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(userMenuAnchor)}
                  onClose={handleCloseUserMenu}
                >
                  <Box sx={{ px: 2, py: 1 }}>
                    <Typography variant="subtitle2" color="primary">
                      {currentUser.email}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {userProfile?.role === 'Paid' ? 'Premium Account' : 'Free Account'}
                    </Typography>
                  </Box>
                  
                  <Divider sx={{ my: 1 }} />
                  
                  {userMenuItems.map((item) => (
                    <MenuItem
                      key={item.name}
                      onClick={item.action ? item.action : () => {
                        handleCloseUserMenu();
                        if (item.path) navigate(item.path);
                      }}
                      sx={{ minWidth: 180 }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <Typography>{item.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <>
                <Button 
                  component={Link} 
                  to="/login" 
                  color="inherit" 
                  sx={{ mr: { xs: 1, sm: 2 } }}
                >
                  Login
                </Button>
                <Button 
                  component={Link} 
                  to="/signup" 
                  variant="contained" 
                  color="primary"
                >
                  Sign Up
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavigationBar;