import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Paper,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  Fade,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import ImageIcon from '@mui/icons-material/Image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublishIcon from '@mui/icons-material/Publish';
import CloseIcon from '@mui/icons-material/Close';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';

// Styled components with shouldForwardProp to prevent custom props from being passed to DOM
const UploadPaper = styled(Paper, {
  shouldForwardProp: (prop) => !['isDragActive', 'hasError', 'hasImage'].includes(prop)
})(({ theme, isDragActive, hasError, hasImage }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  border: `2px dashed ${isDragActive 
    ? theme.palette.primary.main 
    : hasError 
      ? theme.palette.error.main 
      : hasImage 
        ? theme.palette.success.light 
        : theme.palette.divider}`,
  backgroundColor: isDragActive
    ? alpha(theme.palette.primary.main, 0.05)
    : hasError
      ? alpha(theme.palette.error.main, 0.05)
      : hasImage
        ? alpha(theme.palette.success.light, 0.05)
        : theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden'
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Improved helper function to create an alpha value of a color
function alpha(color, value) {
  // Handle rgba format
  if (typeof color === 'string' && color.startsWith('rgba')) {
    return color.replace(/rgba\(([^,]+),([^,]+),([^,]+),[^)]+\)/, 
      (_, r, g, b) => `rgba(${r},${g},${b},${value})`);
  }
  
  // Handle rgb format
  if (typeof color === 'string' && color.startsWith('rgb')) {
    return color.replace(/rgb\(([^,]+),([^,]+),([^,]+)\)/, 
      (_, r, g, b) => `rgba(${r},${g},${b},${value})`);
  }
  
  // Handle hex format
  if (typeof color === 'string' && color.startsWith('#')) {
    return `${color}${Math.round(value * 255)
      .toString(16)
      .padStart(2, '0')}`;
  }
  
  // Fallback for theme colors or named colors
  return `${color}${Math.round(value * 255).toString(16).padStart(2, '0')}`;
}

/**
 * Image Upload Component with drag and drop functionality
 * 
 * @param {Object} props
 * @param {Function} props.onImageUpload - Callback when image is uploaded
 * @param {string} props.uploadedImage - Data URL of the uploaded image
 * @param {boolean} props.disabled - Whether the component is disabled
 */
const ImageUpload = ({ onImageUpload, uploadedImage, disabled = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [localImage, setLocalImage] = useState(uploadedImage);
  
  const inputRef = useRef(null);

  // Sync local state with prop
  useEffect(() => {
    setLocalImage(uploadedImage);
    // If uploadedImage becomes null, also reset fileInfo
    if (!uploadedImage) {
      setFileInfo(null);
    }
  }, [uploadedImage]);

  // Define acceptable image types and max size
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/webp'];
  const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

  // Validate file type and size
  const validateFile = (file) => {
    if (!file) return null;
    
    // Check file type
    if (!acceptedTypes.includes(file.type)) {
      return 'Please upload a JPEG, PNG, or WebP image.';
    }
    
    // Check file size
    if (file.size > maxSizeInBytes) {
      return 'File is too large. Maximum size is 10MB.';
    }
    
    return null;
  };

  // Process file(s) dropped or selected
  const handleFiles = (files) => {
    if (!files || files.length === 0) return;
    
    const file = files[0];
    const validationError = validateFile(file);
    
    if (validationError) {
      setError(validationError);
      return;
    }
    
    setError(null);
    setFileInfo({
      name: file.name,
      size: Math.round(file.size / 1024),
      type: file.type
    });
    
    // Call the parent component's onImageUpload handler
    onImageUpload(file);
  };

  // Handle file input change
  const handleChange = (event) => {
    handleFiles(event.target.files);
  };

  // Handle drag events
  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (disabled) return;
    
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // Handle file drop
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (disabled) return;
    
    setDragActive(false);
    
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFiles(event.dataTransfer.files);
    }
  };

  // Handle file selection button click
  const handleButtonClick = (event) => {
    if (disabled) return;
    
    // Prevent default behavior to avoid any unwanted side effects
    if (event) event.preventDefault();
    
    // Reset the input value to ensure onChange fires even with the same file
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  // Remove the uploaded image
  const handleDelete = () => {
    // First update the local state immediately for UI responsiveness
    setLocalImage(null);
    setFileInfo(null);
    setError(null);
    
    // Then call the parent component's callback with null to clear the image
    onImageUpload(null);
  };
  
  // Toggle the image preview dialog
  const toggleImageDialog = () => {
    setShowImageDialog(!showImageDialog);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" fontWeight="medium" sx={{ 
        mb: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 0.5
      }}>
        <ImageIcon fontSize="small" />
        Car Image
      </Typography>
      
      {/* Hidden input moved outside conditional rendering so it's always available */}
      <VisuallyHiddenInput
        ref={inputRef}
        type="file"
        accept="image/jpeg,image/png,image/webp"
        onChange={handleChange}
        disabled={disabled}
      />
      
      <UploadPaper
        elevation={0}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        isDragActive={dragActive}
        hasError={!!error}
        hasImage={!!localImage}
        sx={{ opacity: disabled ? 0.7 : 1 }}
      >
        {!localImage ? (
          <Box sx={{ py: isMobile ? 2 : 3 }}>
            <FileUploadIcon
              color="primary"
              sx={{ 
                fontSize: isMobile ? 40 : 48, 
                mb: 1,
                opacity: disabled ? 0.5 : 1
              }}
            />
            
            <Typography variant="body1" gutterBottom>
              {dragActive 
                ? 'Drop your image here' 
                : 'Drag & drop your car image here'}
            </Typography>
            
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              or
            </Typography>
            
            <Button
              variant="contained"
              startIcon={<PublishIcon />}
              disabled={disabled}
              onClick={handleButtonClick}
              sx={{ px: 3 }}
            >
              Browse Files
            </Button>
            
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 2 }}>
              Supported formats: JPG, PNG, WebP (max 10MB)
            </Typography>
          </Box>
        ) : (
          <Box sx={{ position: 'relative' }}>
            {fileInfo && (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                px: 1,
                py: 0.5
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <CheckCircleIcon color="success" fontSize="small" />
                  <Typography variant="caption" color="text.secondary">
                    {fileInfo.name} ({fileInfo.size} KB)
                  </Typography>
                </Box>
                
                <Box>
                  <Tooltip title="View Image">
                    <IconButton 
                      size="small" 
                      onClick={toggleImageDialog}
                      disabled={disabled}
                    >
                      <PhotoSizeSelectActualIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  
                  <Tooltip title="Remove Image">
                    <IconButton 
                      size="small" 
                      onClick={handleDelete}
                      disabled={disabled}
                      color="error"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            )}
            
            <Box sx={{ pt: 1, pb: 2, position: 'relative' }}>
              <img 
                src={localImage} 
                alt="Car thumbnail" 
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '160px', 
                  borderRadius: theme.shape.borderRadius,
                  border: `1px solid ${theme.palette.divider}`,
                  opacity: disabled ? 0.7 : 1
                }}
              />
              
              <Fade in={!disabled}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={disabled}
                  onClick={handleButtonClick}
                  sx={{ 
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    opacity: 0.9
                  }}
                >
                  Replace
                </Button>
              </Fade>
            </Box>
          </Box>
        )}
      </UploadPaper>
      
      {/* Error message */}
      <Collapse in={!!error}>
        <Box sx={{ 
          p: 1, 
          mb: 2, 
          bgcolor: alpha(theme.palette.error.light, 0.1),
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <InfoIcon color="error" fontSize="small" />
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        </Box>
      </Collapse>
      
      {/* Usage tip */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'flex-start', 
        gap: 0.5,
        px: 1,
        opacity: localImage ? 0.5 : 1
      }}>
        <InfoIcon color="info" fontSize="small" sx={{ mt: 0.3 }} />
        <Typography variant="caption" color="text.secondary">
          For best results, use a clear photo of your car from the front, side, or 3/4 view
          without strong shadows or reflections.
        </Typography>
      </Box>
      
      {/* Image preview dialog */}
      <Dialog
        open={showImageDialog}
        onClose={toggleImageDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {fileInfo?.name || 'Car Image'}
          <IconButton
            aria-label="close"
            onClick={toggleImageDialog}
            sx={{ color: theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ textAlign: 'center', p: 0, backgroundColor: theme.palette.grey[100] }}>
          <img 
            src={localImage} 
            alt="Car" 
            style={{ 
              maxWidth: '100%', 
              maxHeight: '70vh',
              objectFit: 'contain'
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleImageDialog}>Close</Button>
          <Button 
            variant="outlined" 
            color="error" 
            startIcon={<DeleteIcon />}
            onClick={() => {
              handleDelete();
              toggleImageDialog();
            }}
          >
            Remove Image
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImageUpload;