import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slider,
  FormControl,
  FormLabel,
  Checkbox,
  Paper,
  IconButton,
  CircularProgress,
  Alert,
  AlertTitle,
  Tooltip,
  Divider,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import GetAppIcon from '@mui/icons-material/GetApp';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/Filter';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Styled components
const PreviewContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  overflow: 'hidden',
  maxHeight: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PreviewImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '180px',
  objectFit: 'contain',
});

/**
 * Dialog for saving generated car images
 * 
 * @param {Object} props
 * @param {boolean} props.open - Whether the dialog is open
 * @param {Function} props.onClose - Function to call when dialog is closed
 * @param {string} props.resultImage - URL of the image to save
 */
const SaveDialog = ({ open, onClose, resultImage }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [format, setFormat] = useState('png');
  const [quality, setQuality] = useState(90);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [optimize, setOptimize] = useState(true);
  
  // Reset state when dialog is opened
  useEffect(() => {
    if (open) {
      setSuccess(false);
      setError(null);
    }
  }, [open]);
  
  // Handle format change
  const handleFormatChange = (event) => {
    setFormat(event.target.value);
  };
  
  // Handle quality slider change
  const handleQualityChange = (event, newValue) => {
    setQuality(newValue);
  };
  
  // Handle direct download
  const handleDownload = async () => {
    if (!resultImage) {
      setError('No image to save');
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      // Create an anchor element and trigger download
      const a = document.createElement('a');
      a.href = resultImage;
      a.download = `car-color-changer-${Date.now()}.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      
      setSuccess(true);
      
      // Auto-close after success
      setTimeout(() => {
        if (open) onClose();
      }, 1500);
      
    } catch (err) {
      console.error('Error downloading image:', err);
      setError('Failed to download the image: ' + err.message);
    } finally {
      setLoading(false);
    }
  };
  
  // Handle share functionality (if supported by browser)
  const handleShare = async () => {
    if (!resultImage || !navigator.share) {
      setError('Sharing is not supported on this device or browser');
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      // Convert data URL to Blob for sharing
      const fetchResponse = await fetch(resultImage);
      const blob = await fetchResponse.blob();
      
      // Create file object from blob
      const file = new File([blob], `car-color-${Date.now()}.${format}`, { type: `image/${format}` });
      
      // Use Web Share API
      await navigator.share({
        title: 'My Car Color Design',
        text: 'Check out this car color design I created with VisionStudio!',
        files: [file]
      });
      
      setSuccess(true);
    } catch (err) {
      console.error('Error sharing image:', err);
      if (err.name !== 'AbortError') {
        setError('Failed to share the image: ' + err.message);
      }
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={loading ? null : onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="save-dialog-title"
      PaperProps={{
        elevation: 5,
        sx: { borderRadius: 2 }
      }}
    >
      <DialogTitle id="save-dialog-title" sx={{ pb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
            <SaveIcon sx={{ mr: 1 }} />
            Save Your Design
          </Typography>
          {!loading && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      
      <DialogContent dividers>
        {/* Success message */}
        {success && (
          <Alert 
            severity="success" 
            sx={{ mb: 2 }}
            icon={<CheckCircleIcon fontSize="inherit" />}
          >
            <AlertTitle>Success!</AlertTitle>
            Your image has been saved successfully.
          </Alert>
        )}
        
        {/* Error message */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        {/* Image Preview */}
        <PreviewContainer>
          {resultImage && <PreviewImage src={resultImage} alt="Result preview" />}
        </PreviewContainer>
        
        {/* File Format Selection */}
        <FormControl component="fieldset" sx={{ mb: 3, width: '100%' }}>
          <FormLabel component="legend" sx={{ mb: 1, fontWeight: 'medium' }}>
            File Format
          </FormLabel>
          <RadioGroup
            row
            value={format}
            onChange={handleFormatChange}
          >
            <FormControlLabel 
              value="png" 
              control={<Radio />} 
              label={
                <Tooltip title="Lossless quality, larger file size" arrow>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">PNG</Typography>
                    <InfoIcon sx={{ ml: 0.5, fontSize: '0.875rem', color: 'text.secondary' }} />
                  </Box>
                </Tooltip>
              }
            />
            <FormControlLabel 
              value="jpg" 
              control={<Radio />} 
              label={
                <Tooltip title="Smaller file size, some quality loss" arrow>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">JPEG</Typography>
                    <InfoIcon sx={{ ml: 0.5, fontSize: '0.875rem', color: 'text.secondary' }} />
                  </Box>
                </Tooltip>
              }
            />
            <FormControlLabel 
              value="webp" 
              control={<Radio />} 
              label={
                <Tooltip title="Modern format with good compression" arrow>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">WebP</Typography>
                    <InfoIcon sx={{ ml: 0.5, fontSize: '0.875rem', color: 'text.secondary' }} />
                  </Box>
                </Tooltip>
              }
            />
          </RadioGroup>
        </FormControl>
        
        {/* Quality Slider (for JPG/WebP) */}
        {format !== 'png' && (
          <Box sx={{ mb: 3 }}>
            <Typography id="quality-slider" gutterBottom variant="body2" fontWeight="medium">
              Quality
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FilterIcon sx={{ mr: 2, color: 'text.secondary' }} />
              <Slider
                value={quality}
                onChange={handleQualityChange}
                aria-labelledby="quality-slider"
                valueLabelDisplay="auto"
                step={5}
                marks={[
                  { value: 50, label: '50%' },
                  { value: 75, label: '75%' },
                  { value: 100, label: '100%' }
                ]}
                min={50}
                max={100}
                disabled={format === 'png'}
              />
            </Box>
            <Typography variant="caption" color="text.secondary">
              Higher quality = larger file size
            </Typography>
          </Box>
        )}
        
        {/* Optimization Option */}
        <FormControlLabel
          control={
            <Checkbox
              checked={optimize}
              onChange={(e) => setOptimize(e.target.checked)}
            />
          }
          label="Optimize file (smaller size)"
        />
        
        {/* Share tip */}
        {navigator.share && (
          <Box sx={{ mt: 3, p: 2, bgcolor: theme.palette.grey[50], borderRadius: 1 }}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <InfoIcon fontSize="small" color="info" />
              You can also directly share this image with others using the share button below.
            </Typography>
          </Box>
        )}
      </DialogContent>
      
      <DialogActions sx={{ px: 3, py: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        
        <Box>
          {navigator.share && (
            <Button
              variant="outlined"
              onClick={handleShare}
              startIcon={loading ? <CircularProgress size={20} /> : <ShareIcon />}
              disabled={loading || !resultImage}
              sx={{ mr: 1 }}
            >
              Share
            </Button>
          )}
          
          <Button
            variant="contained"
            onClick={handleDownload}
            startIcon={loading ? <CircularProgress size={20} /> : <GetAppIcon />}
            disabled={loading || !resultImage}
            color="primary"
          >
            {loading ? 'Saving...' : 'Download'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SaveDialog;