import React from 'react';
import { 
  Box, 
  Typography, 
  TextField,
  Slider,
  Paper,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TuneIcon from '@mui/icons-material/Tune';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import AdjustIcon from '@mui/icons-material/Adjust';

// Styled components
const SettingsSection = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

/**
 * Component for tooltip labels with an info icon
 */
const LabelWithTooltip = ({ label, tooltip }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
    <Typography variant="body2" fontWeight="medium">
      {label}
    </Typography>
    <Tooltip title={tooltip} arrow placement="top">
      <HelpOutlineIcon
        fontSize="small"
        sx={{ ml: 0.5, color: 'text.secondary', fontSize: '1rem' }}
      />
    </Tooltip>
  </Box>
);

/**
 * Advanced settings component for the car color changer
 * 
 * @param {Object} props
 * @param {string} props.customPrompt - Current custom prompt
 * @param {Function} props.setCustomPrompt - Function to update custom prompt
 * @param {number} props.growMaskValue - Current grow mask value
 * @param {Function} props.setGrowMaskValue - Function to update grow mask value
 * @param {boolean} props.disabled - Whether the component is disabled
 */
const AdvancedSettings = ({
  customPrompt,
  setCustomPrompt,
  growMaskValue,
  setGrowMaskValue,
  disabled = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle custom prompt change
  const handlePromptChange = (event) => {
    if (disabled) return;
    setCustomPrompt(event.target.value);
  };

  // Handle grow mask slider change
  const handleGrowMaskChange = (event, newValue) => {
    if (disabled) return;
    setGrowMaskValue(newValue);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle2" sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mb: 1,
        color: 'text.secondary'
      }}>
        <TuneIcon fontSize="small" sx={{ mr: 0.5 }} />
        Advanced API Settings
      </Typography>
      
      <Accordion 
        defaultExpanded
        disableGutters
        elevation={0}
        sx={{ 
          mb: 2,
          '&:before': { display: 'none' },
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: `${theme.shape.borderRadius}px !important`,
          overflow: 'hidden',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ 
            backgroundColor: theme.palette.grey[50], 
            '&.Mui-expanded': { minHeight: 48 },
            minHeight: 48
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextFormatIcon sx={{ mr: 1, fontSize: '1.2rem', color: theme.palette.primary.main }} />
            <Typography variant="body2" fontWeight="medium">
              Selection Prompt
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <TextField
            value={customPrompt}
            onChange={handlePromptChange}
            multiline
            minRows={2}
            maxRows={4}
            fullWidth
            variant="outlined"
            placeholder="Transform ONLY the main car body. Preserve ALL original wheel and rim colors exactly as they appear."
            size="small"
            disabled={disabled}
            InputProps={{
              sx: { fontSize: '0.875rem' }
            }}
            helperText="Helps the AI identify which parts of the image to change"
          />
          
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color="text.secondary">
              For best results, be specific about which parts of the car should be colored.
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      
      <Accordion 
        defaultExpanded
        disableGutters
        elevation={0}
        sx={{ 
          mb: 2,
          '&:before': { display: 'none' },
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: `${theme.shape.borderRadius}px !important`,
          overflow: 'hidden',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ 
            backgroundColor: theme.palette.grey[50],
            '&.Mui-expanded': { minHeight: 48 },
            minHeight: 48
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AdjustIcon sx={{ mr: 1, fontSize: '1.2rem', color: theme.palette.primary.main }} />
            <Typography variant="body2" fontWeight="medium">
              Mask Growth
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <LabelWithTooltip 
            label="Grow Mask Strength"
            tooltip="Higher values create smoother transitions but may affect details. Lower values keep details sharp but may have harder edges."
          />
          
          <Slider
            value={growMaskValue}
            onChange={handleGrowMaskChange}
            min={0}
            max={20}
            step={1}
            marks={[
              { value: 0, label: '0' },
              { value: 10, label: '10' },
              { value: 20, label: '20' }
            ]}
            disabled={disabled}
          />
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Box sx={{ maxWidth: '32%' }}>
              <Typography variant="caption" color="text.secondary" fontWeight="medium">
                Low (0-5)
              </Typography>
              <Typography variant="caption" display="block" color="text.secondary">
                Sharp transitions, preserves details
              </Typography>
            </Box>
            
            <Box sx={{ maxWidth: '32%' }}>
              <Typography variant="caption" color="text.secondary" fontWeight="medium">
                Medium (6-10)
              </Typography>
              <Typography variant="caption" display="block" color="text.secondary">
                Balanced for most images
              </Typography>
            </Box>
            
            <Box sx={{ maxWidth: '32%' }}>
              <Typography variant="caption" color="text.secondary" fontWeight="medium">
                High (11-20)
              </Typography>
              <Typography variant="caption" display="block" color="text.secondary">
                Smooth transitions, may affect details
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AdvancedSettings;