import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Backdrop,
  Paper,
  LinearProgress,
  Zoom,
  useTheme
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

// Animation keyframes
const pulse = keyframes`
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
`;

// Styled components
const LoadingContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  maxWidth: '90%',
  width: '400px',
  position: 'relative',
  overflow: 'hidden'
}));

const AnimatedMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  animation: `${pulse} 2s infinite ease-in-out`,
  textAlign: 'center',
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
}));

const Progress = styled(Box)({
  position: 'relative',
  width: '100%',
});

const BouncingDot = styled('span')(({ theme, delay }) => ({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  margin: '0 2px',
  animation: `${bounce} 1.4s infinite ease-in-out`,
  animationDelay: `${delay}s`,
}));

// Loading tips to display during processing
const loadingTips = [
  "For best results, use clear photos with good lighting",
  "Photos with minimal reflections produce better color changes",
  "Try different growth values if edges look too sharp",
  "The AI works best with front, side, or 3/4 view photos",
  "You can change both body and rim colors separately",
  "Different finish types can dramatically change the look",
  "Photos with simple backgrounds work best",
  "Try the 'retry' button if you're not happy with the results",
  "Premium finishes create unique metallic and pearlescent effects"
];

/**
 * Full-screen loading overlay with animation and progress indicator
 * 
 * @param {Object} props
 * @param {string} props.message - Message to display during loading
 */
const LoadingOverlay = ({ message = 'Processing...' }) => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  const [tip, setTip] = useState('');
  
  // Simulate progress for better UX
  useEffect(() => {
    const steps = 15; // Total steps
    const startDelay = 500; // Initial delay before progress starts
    const totalTime = 45000; // Expected total time (45 seconds)
    const stepTime = totalTime / steps;
    
    // Select random tip
    setTip(loadingTips[Math.floor(Math.random() * loadingTips.length)]);
    
    let timer;
    const startProgress = () => {
      let step = 0;
      
      // Update progress at each step
      timer = setInterval(() => {
        step += 1;
        // Non-linear progress to feel more natural
        // Faster at the beginning, slower towards the end
        setProgress(prev => {
          if (step <= steps / 3) {
            // First third: faster progress
            return Math.min(prev + 100 / steps * 1.5, 90);
          } else if (step <= steps * 2 / 3) {
            // Middle third: normal progress
            return Math.min(prev + 100 / steps, 90);
          } else {
            // Last third: slower progress
            return Math.min(prev + 100 / steps * 0.5, 90);
          }
        });
        
        // Stop at 90% - the final 10% will happen when the actual result arrives
        if (step >= steps || progress >= 90) {
          clearInterval(timer);
        }
      }, stepTime);
    };
    
    // Start progress after initial delay
    const delayTimer = setTimeout(startProgress, startDelay);
    
    // Cleanup
    return () => {
      clearTimeout(delayTimer);
      clearInterval(timer);
    };
  }, []);
  
  return (
    <StyledBackdrop open={true}>
      <Zoom in={true}>
        <LoadingContainer>
          <Box sx={{ position: 'relative' }}>
            <CircularProgress size={64} thickness={5} />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                {Math.round(progress)}%
              </Typography>
            </Box>
          </Box>
          
          <AnimatedMessage variant="h6" color="primary" sx={{ mt: 3, mb: 3, fontWeight: 'medium' }}>
            {message}
            <BouncingDot delay={0} />
            <BouncingDot delay={0.2} />
            <BouncingDot delay={0.4} />
          </AnimatedMessage>
          
          <Progress sx={{ width: '100%', mt: 1, mb: 3 }}>
            <LinearProgress 
              variant="determinate" 
              value={progress} 
              sx={{ height: 8, borderRadius: 4 }}
            />
          </Progress>
          
          <Box sx={{ 
            mt: 1, 
            p: 2, 
            bgcolor: theme.palette.grey[100], 
            borderRadius: 1,
            width: '100%',
            border: `1px solid ${theme.palette.divider}`
          }}>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              align="center"
              sx={{ fontStyle: 'italic' }}
            >
              <span role="img" aria-label="Tip">💡</span> {tip}
            </Typography>
          </Box>
        </LoadingContainer>
      </Zoom>
    </StyledBackdrop>
  );
};

export default LoadingOverlay;