import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

// Color prompt building utilities
const colorPrompts = {
  'Red': 'car color (red:1.0)',
  'Blue': 'car color (blue:1.0)',
  'Green': 'car color (green:1.0)',
  'Yellow': 'car color (yellow:1.0)',
  'Black': 'car color (absolute black:1.0) (000000:1.0) (vantablack:1.0), completely pure black with no gray tones whatsoever, darkest possible black, 100% black, RGB(0,0,0), not dark gray but true black',
  'White': 'car color (white:1.0)',
  'Orange': 'car color (orange:1.0)',
  'Purple': 'car color (purple:1.0)',
  'Pink': 'car color (pink:1.0)',
  'Brown': 'car color (brown:1.0)',
  'Gray': 'car color (gray:1.0)',
  'Silver': 'car color (silver:1.0)',
  'Gold': 'car color (gold:1.0)',
  'Teal': 'car color (teal:1.0)',
  'Navy': 'car color (navy:1.0)'
};

const rimColorPrompts = {
  'Red': 'rim color (red:1.0)',
  'Blue': 'rim color (blue:1.0)',
  'Green': 'rim color (green:1.0)',
  'Yellow': 'rim color (yellow:1.0)',
  'Black': 'rim color (absolute black:1.0) (000000:1.0), completely pure black with no gray tones whatsoever',
  'White': 'rim color (white:1.0)',
  'Orange': 'rim color (orange:1.0)',
  'Purple': 'rim color (purple:1.0)',
  'Pink': 'rim color (pink:1.0)',
  'Brown': 'rim color (brown:1.0)',
  'Gray': 'rim color (gray:1.0)',
  'Silver': 'rim color (silver:1.0) (chrome:0.8)',
  'Gold': 'rim color (gold:1.0)',
  'Teal': 'rim color (teal:1.0)',
  'Navy': 'rim color (navy:1.0)'
};

// API endpoints
const API_ENDPOINTS = {
  searchAndRecolor: 'https://api.stability.ai/v2beta/stable-image/edit/search-and-recolor',
  inpaint: 'https://api.stability.ai/v2beta/stable-image/edit/inpaint'
};

/**
 * Retrieves the Stability AI API key from Firebase
 * @returns {Promise<string>} API key
 */
const getStabilityApiKey = async () => {
  try {
    // Get the API key from Firestore
    const apiKeyDoc = await getDoc(doc(db, 'appSettings', 'stabilityAi'));
    
    if (apiKeyDoc.exists()) {
      return apiKeyDoc.data().apiKey;
    } else {
      throw new Error('API key not configured');
    }
  } catch (error) {
    console.error('Error fetching Stability API key:', error);
    throw new Error('Could not retrieve API credentials. Please contact support.');
  }
};

/**
 * Calls the Stability AI API to change car color
 * @param {string} imageUrl - URL of the image to process
 * @param {object} colorData - Color selection and options
 * @returns {Promise<object>} Result with success status and URL
 */
export const callStabilityAPI = async (imageUrl, colorData) => {
  try {
    const storage = getStorage();
    
    // Get API key from Firestore
    const apiKey = await getStabilityApiKey();
    
    // Destructure color data
    const {
      selectedColor,
      selectedFinish,
      enableRimColorChange,
      selectedRimColor,
      apiMethod = 'search_and_recolor',
      customPrompt,
      growMaskValue = 3
    } = colorData;
    
    // Get the endpoint based on chosen method
    const endpoint = apiMethod === 'search_and_recolor' 
      ? API_ENDPOINTS.searchAndRecolor 
      : API_ENDPOINTS.inpaint;
    
    // Build the prompts
    const bodyColorPrompt = colorPrompts[selectedColor] || `car color (${selectedColor.toLowerCase()}:1.0)`;
    let rimColorPrompt = null;
    
    if (enableRimColorChange && selectedRimColor) {
      rimColorPrompt = rimColorPrompts[selectedRimColor] || `rim color (${selectedRimColor.toLowerCase()}:1.0)`;
    }
    
    // Create prompts based on the selected options
    let prompt, negativePrompt;
    
    if (enableRimColorChange && rimColorPrompt) {
      // Prompt for both body and rim colors
      prompt = (
        `Professional car color change to ${bodyColorPrompt} for the car body, ` +
        `with wheels and rims changed to ${rimColorPrompt}, ` +
        `showing same exact car model, same exact viewpoint, same lighting, same background, ` +
        `absolutely no changes to car shape or details`
      );
      
      negativePrompt = (
        "different car model, changed car shape, changed background, distorted proportions, " +
        "damaged car, color bleeding, wrong color, grayish tone, faded color, washed out color, " +
        "muted color, gray tone, body color on rims, rim color on body"
      );
    } else {
      // Prompt for body color only
      prompt = (
        `Professional car color change to ${bodyColorPrompt}, showing same exact car model, ` +
        `same exact viewpoint, same lighting, same background, ` +
        `PRESERVE ORIGINAL WHEELS AND RIMS EXACTLY AS THEY ARE, absolutely no changes to car shape or details`
      );
      
      negativePrompt = (
        "different car model, changed car shape, changed background, distorted proportions, " +
        "damaged car, changed wheels, changed rims, modified wheels, modified rims, " +
        "color bleeding, wrong color, grayish tone, faded color, washed out color, muted color, gray tone"
      );
    }
    
    // Fetch the image from Firebase Storage
    const response = await fetch(imageUrl);
    const imageBlob = await response.blob();
    
    // Create FormData for the API request
    const formData = new FormData();
    formData.append('image', imageBlob, 'car.jpg');
    
    // If using inpaint method, generate a simple mask
    if (apiMethod === 'inpaint') {
      // For simplicity, create a white mask that covers the entire image
      const maskCanvas = document.createElement('canvas');
      const img = new Image();
      
      // Load the image to get dimensions
      await new Promise((resolve) => {
        img.onload = resolve;
        img.src = imageUrl;
      });
      
      // Set canvas dimensions to match image
      maskCanvas.width = img.width;
      maskCanvas.height = img.height;
      
      // Fill with white (all areas to be processed)
      const ctx = maskCanvas.getContext('2d');
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);
      
      // Convert canvas to blob
      const maskBlob = await new Promise(resolve => 
        maskCanvas.toBlob(resolve, 'image/png')
      );
      
      // Add mask to form data
      formData.append('mask', maskBlob, 'mask.png');
    }
    
    // Add remaining form data parameters
    formData.append('prompt', prompt);
    formData.append('select_prompt', customPrompt);
    formData.append('negative_prompt', negativePrompt);
    formData.append('grow_mask', growMaskValue.toString());
    formData.append('output_format', 'png');
    formData.append('cfg_scale', '7.5');
    formData.append('steps', '30');
    formData.append('seed', '0');
    formData.append('output_resolution', 'high');
    
    // Call the Stability AI API
    const apiResponse = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Accept': 'image/*'
      },
      body: formData
    });
    
    // Handle API errors
    if (!apiResponse.ok) {
      let errorMsg = 'API request failed';
      
      try {
        // Try to get error details from response
        const errorData = await apiResponse.json();
        errorMsg = errorData.message || errorData.error || errorMsg;
      } catch (e) {
        // If not JSON, use status text
        errorMsg = apiResponse.statusText || errorMsg;
      }
      
      // Special handling for common errors
      if (apiResponse.status === 402) {
        errorMsg = 'Payment required. Please check the Stability AI account credits.';
      } else if (apiResponse.status === 400 && errorMsg.includes('content_moderation')) {
        errorMsg = 'Your request was flagged by content moderation. Please try a different image.';
      }
      
      throw new Error(errorMsg);
    }
    
    // Get the result as blob
    const resultBlob = await apiResponse.blob();
    
    // Upload the result to Firebase Storage
    const timestamp = Date.now();
    const resultPath = `car_color_results/${timestamp}_result.png`;
    const resultRef = ref(storage, resultPath);
    
    await uploadBytes(resultRef, resultBlob, {
      contentType: 'image/png',
      customMetadata: {
        originalColor: selectedColor,
        finish: selectedFinish,
        rimColor: enableRimColorChange ? selectedRimColor : 'none',
        timestamp: timestamp.toString()
      }
    });
    
    // Get the download URL
    const resultImageUrl = await getDownloadURL(resultRef);
    
    return {
      success: true,
      resultImageUrl
    };
    
  } catch (error) {
    console.error('Stability API error:', error);
    return {
      success: false,
      error: error.message || 'An error occurred while processing the image'
    };
  }
};

/**
 * Creates a simple white mask for inpainting
 * @param {HTMLImageElement} image - Original image
 * @returns {Promise<Blob>} Mask as blob
 */
export const createSimpleMask = async (image) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/png');
  });
};