import React from 'react';
import { Box, Container, useTheme, useMediaQuery } from '@mui/material';
import NavigationBar from './NavigationBar';
import Footer from './Footer';

/**
 * PageContainer provides a consistent layout wrapper for all pages
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.children - Page content
 * @param {boolean} props.fullWidth - Whether the container should be full width
 * @param {string} props.maxWidth - Max width of content (xs, sm, md, lg, xl)
 * @param {Object} props.sx - Additional styles to apply to the container
 * @param {string} props.bgColor - Background color of the page
 * @param {boolean} props.noPadding - Remove default padding
 */
const PageContainer = ({ 
  children, 
  fullWidth = false, 
  maxWidth = 'lg',
  sx = {},
  bgColor = 'background.default',
  noPadding = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Calculate padding based on screen size and noPadding prop
  const getPadding = () => {
    if (noPadding) return 0;
    if (isMobile) return 2;
    return 4;
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        bgcolor: bgColor,
      }}
    >
      {/* Navigation Bar */}
      <NavigationBar />
      
      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: getPadding(),
          ...sx,
        }}
      >
        {fullWidth ? (
          children
        ) : (
          <Container maxWidth={maxWidth} sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
            {children}
          </Container>
        )}
      </Box>
      
      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default PageContainer;