import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../services/firebase'; // Import db from firebase.js
import { useNavigate, Link } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography, Paper} from '@mui/material';
import NavigationBar from '../layout/NavigationBar';




const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const provider = new GoogleAuthProvider();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(''); // Reset error state before a new signup attempt

    try {
      // Create the user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Update the user's display name in Firebase Auth
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });

      // Create a user document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        firstName,
        lastName,
        email,
        role: 'User',
      });

      // Redirect the user to a new page (e.g., demo page)
      navigate('/'); // Redirect to your demo page after successful signup
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use.');
      } else {
        setError('Could not create account. Please try again.');
      }
      console.error('Error signing up: ', error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user exists in Firestore, if not create a new document
      const userDoc = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userDoc);

      if (!docSnap.exists()) {
        // If the user is new, create a new document in Firestore
        await setDoc(userDoc, {
          firstName: user.displayName.split(' ')[0],
          lastName: user.displayName.split(' ')[1],
          email: user.email,
          role: 'User',
        });
      }

      navigate('/');
    } catch (error) {
      setError('Google Sign-In failed. Please try again.');
      console.error('Error with Google Sign-In: ', error);
    }
  };

  return (
    <>
      <NavigationBar /> {/* Add navigation bar here */}
      <Container maxWidth="sm">
        <Box sx={{ mt: 8 }}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
              Create an Account
            </Typography>
            {error && (
              <Typography variant="body2" color="error" sx={{ mb: 2, textAlign: 'center' }}>
                {error}
              </Typography>
            )}
            <form onSubmit={handleSignup}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{ mb: 3 }}
                required
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{ mb: 3 }}
                required
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 3 }}
                required
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 3 }}
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ py: 1.5, mb: 3 }}
              >
                Sign Up
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleGoogleSignIn}
              >
                Sign up with Google
              </Button>
            </form>

            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Already have an account?{' '}
              <Button component={Link} to="/login" color="secondary" sx={{ textTransform: 'none' }}>
                Login
              </Button>
              
            </Typography>


          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Signup;
