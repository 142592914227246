/**
 * Color libraries and definitions for car color changer
 */

// Material finish properties for 3D visualization
export const finishes = {
    gloss: {
      metalness: 0.7,
      roughness: 0.2,
      clearcoat: 1.0,
      clearcoatRoughness: 0.1,
      reflectivity: 1.0,
    },
    satin: {
      metalness: 0.5,
      roughness: 0.4,
      clearcoat: 0.6,
      clearcoatRoughness: 0.2,
      reflectivity: 0.8,
    },
    matte: {
      metalness: 0.2,
      roughness: 0.9,
      clearcoat: 0.0,
      reflectivity: 0.3,
    },
    premium: {
      metalness: 0.8,
      roughness: 0.1,
      clearcoat: 1.0,
      clearcoatRoughness: 0.1,
      reflectivity: 1.0,
    }
  };
  
  // Main color library with all available colors by finish type
  export const colorLibrary = {
    gloss: [
      { name: 'White Gold Sparkle', color: '#F2E3CD' },
      { name: 'White', color: '#E6E6E6' },
      { name: 'Light Ivory', color: '#F0E2C3' },
      { name: 'Sky Blue', color: '#8EBBD9' },
      { name: 'Atlantic Teal', color: '#4B7E8F' },
      { name: 'Kelly Green', color: '#3C7C44' },
      { name: 'Green Envy', color: '#47844C' },
      { name: 'Lemon Sting', color: '#F6CE3D' },
      { name: 'Sunflower', color: '#ED8C30' },
      { name: 'Burst Orange', color: '#D9602A' },
      { name: 'Liquid Copper', color: '#B64C34' },
      { name: 'Fiery Orange', color: '#E65A3D' },
      { name: 'Hot Rod Red', color: '#BC3136' },
      { name: 'Dark Red', color: '#8E2B3D' },
      { name: 'Dragon Fire Red', color: '#B2292E' },
      { name: 'Red Metallic', color: '#992932' },
      { name: 'Cinder Spark Red', color: '#A93841' },
      { name: 'Hot Pink', color: '#D83F6B' },
      { name: 'Fierce Fuchsia', color: '#CF2853' },
      { name: 'Plum Explosion', color: '#6D3862' },
      { name: 'Bolt Blue', color: '#235285' },
      { name: 'Blue Metallic', color: '#2F4665' },
      { name: 'Blue Raspberry', color: '#394E6D' },
      { name: 'Cosmic Blue', color: '#3D4A7C' },
      { name: 'Intense Blue', color: '#3C4986' },
      { name: 'Plum Purple', color: '#40344E' },
      { name: 'Ice Blue', color: '#5D717E' },
      { name: 'Silver Metallic', color: '#969494' },
      { name: 'Satin Gray', color: '#A1A1A1' },
      { name: 'Anthracite', color: '#5E5E5E' },
      { name: 'Gunmetal Gray', color: '#484C4E' },
      { name: 'Charcoal Metallic', color: '#323334' },
      { name: 'Midnight Blue', color: '#141A44' },
      { name: 'Wicked', color: '#13162B' },
      { name: 'Black Rose', color: '#3E2E31' },
      { name: 'Ember Black', color: '#1F1C1C' },
      { name: 'Black Metallic', color: '#181716' },
      { name: 'Galaxy Black', color: '#101010' },
      { name: 'Black', color: '#0D0D0D' },
    ],
    
    satin: [
      { name: 'Smoldering Red', color: '#9B2820' },
      { name: 'Vampire Red', color: '#722020' },
      { name: 'Canyon Copper', color: '#C46327' },
      { name: 'Bitter Yellow', color: '#C89735' },
      { name: 'Apple Green', color: '#7BA044' },
      { name: 'Key West', color: '#70C1BB' },
      { name: 'Ocean Shimmer', color: '#307B87' },
      { name: 'Perfect Blue', color: '#316995' },
      { name: 'White', color: '#E5E5E0' },
      { name: 'Frozen Vanilla', color: '#E5E1DB' },
      { name: 'Pearl White', color: '#F0E4C8' },
      { name: 'White Aluminum', color: '#D0D4D5' },
      { name: 'Battleship Gray', color: '#656C70' },
      { name: 'Dark Gray', color: '#494B4D' },
      { name: 'Thundercloud', color: '#404547' },
      { name: 'Gold Dust Black', color: '#383230' },
      { name: 'Black', color: '#2F2F2E' },
      { name: 'Caramel Luster', color: '#8A6246' },
    ],
  
    matte: [
      { name: 'White', color: '#E6E6E6' },
      { name: 'Red Metallic', color: '#A22B34' },
      { name: 'Red', color: '#C83D38' },
      { name: 'Orange', color: '#EE742A' },
      { name: 'Pine Green Metallic', color: '#3A5644' },
      { name: 'Military Green', color: '#4F5C3B' },
      { name: 'Riviera Blue', color: '#4C739E' },
      { name: 'Blue Metallic', color: '#3B587A' },
      { name: 'Indigo', color: '#2D3C4B' },
      { name: 'Deep Black', color: '#1B1B1B' },
      { name: 'Dead Matte Black', color: '#0F0F0F' },
      { name: 'Black', color: '#2B2B2B' },
      { name: 'Charcoal Metallic', color: '#4B4A4A' },
      { name: 'Dark Gray', color: '#5A5A5A' },
      { name: 'Silver', color: '#B2B2B2' },
      { name: 'Gray Aluminum', color: '#8B8D8D' },
      { name: 'Brown Metallic', color: '#5D4737' },
      { name: 'Copper Metallic', color: '#946A45' },
    ],
  
    premium: [
      { name: 'Satin Flip Ghost Pearl', color: '#D8D5CE' },
      { name: 'Satin Flip Volcanic Flare', color: '#8F4C3D' },
      { name: 'Gloss Flip Deep Space', color: '#3D3F52' },
      { name: 'Gloss Flip Electric Wave', color: '#3A567D' },
      { name: 'Satin Flip Glacial Frost', color: '#7B8E9B' },
      { name: 'Satin Flip Caribbean Shimmer', color: '#5C787A' },
      { name: 'Gloss Flip Psychedelic', color: '#94817B' },
      { name: 'Nebula Pearl', color: '#4D5A8C' },
      { name: 'Amethyst Dream', color: '#694873' },
      { name: 'Emerald Essence', color: '#1D6A54' },
      { name: 'Ruby Illusion', color: '#AB2D3F' },
      { name: 'Sapphire Enigma', color: '#254675' },
      { name: 'Bronze Symphony', color: '#9C6B4E' },
      { name: 'Prismatic Silver', color: '#B9C0C4' },
    ],
  };
  
  // Simplified color names for API prompts
  export const basicColors = [
    'Red',
    'Blue',
    'Green',
    'Yellow',
    'Black',
    'White',
    'Orange',
    'Purple',
    'Pink',
    'Brown',
    'Gray',
    'Silver',
    'Gold',
    'Teal',
    'Navy'
  ];
  
  // Map color names to hex values
  export const colorToHex = {
    'Red': '#FF0000',
    'Blue': '#0000FF',
    'Green': '#008000',
    'Yellow': '#FFFF00',
    'Black': '#000000',
    'White': '#FFFFFF',
    'Orange': '#FFA500',
    'Purple': '#800080',
    'Pink': '#FFC0CB',
    'Brown': '#A52A2A',
    'Gray': '#808080',
    'Silver': '#C0C0C0',
    'Gold': '#FFD700',
    'Teal': '#008080',
    'Navy': '#000080'
  };
  
  // Simple color prompts with weight syntax for Stability AI
  export const colorPrompts = {
    'Red': 'car color (red:1.0)',
    'Blue': 'car color (blue:1.0)',
    'Green': 'car color (green:1.0)',
    'Yellow': 'car color (yellow:1.0)',
    'Black': 'car color (absolute black:1.0) (000000:1.0) (vantablack:1.0), completely pure black with no gray tones whatsoever, darkest possible black, 100% black, RGB(0,0,0), not dark gray but true black',
    'White': 'car color (white:1.0)',
    'Orange': 'car color (orange:1.0)',
    'Purple': 'car color (purple:1.0)',
    'Pink': 'car color (pink:1.0)',
    'Brown': 'car color (brown:1.0)',
    'Gray': 'car color (gray:1.0)',
    'Silver': 'car color (silver:1.0)',
    'Gold': 'car color (gold:1.0)',
    'Teal': 'car color (teal:1.0)',
    'Navy': 'car color (navy:1.0)'
  };
  
  // Rim color prompts for Stability AI
  export const rimColorPrompts = {
    'Red': 'rim color (red:1.0)',
    'Blue': 'rim color (blue:1.0)',
    'Green': 'rim color (green:1.0)',
    'Yellow': 'rim color (yellow:1.0)',
    'Black': 'rim color (absolute black:1.0) (000000:1.0), completely pure black with no gray tones whatsoever',
    'White': 'rim color (white:1.0)',
    'Orange': 'rim color (orange:1.0)',
    'Purple': 'rim color (purple:1.0)',
    'Pink': 'rim color (pink:1.0)',
    'Brown': 'rim color (brown:1.0)',
    'Gray': 'rim color (gray:1.0)',
    'Silver': 'rim color (silver:1.0) (chrome:0.8)',
    'Gold': 'rim color (gold:1.0)',
    'Teal': 'rim color (teal:1.0)',
    'Navy': 'rim color (navy:1.0)'
  };
  
  // Selection prompts for car body only
  export const selectionPrompts = [
    "car body panels only, vehicle exterior paint only, car paint only, do not include wheels or rims",
    "car body paint, exterior panels only, no wheels, no rims, no windows",
    "only the paint on the car body, exclude wheels and all other components",
    "car exterior paint job only, specifically excluding wheels and rims"
  ];
  
  // Selection prompts for car body and rims
  export const rimSelectionPrompts = [
    "car body panels and wheels/rims, vehicle exterior paint and wheel rims",
    "car exterior paint and wheel rims, include both body panels and wheels",
    "car body and wheel rims, include both exterior paint and wheel color"
  ];
  
  // Get color info by name
  export const getColorInfo = (colorName, finishType = 'gloss') => {
    if (!colorName || !finishType) return null;
    
    // Check if finish exists
    if (!colorLibrary[finishType]) return null;
    
    // Find color by name
    return colorLibrary[finishType].find(color => color.name === colorName) || null;
  };
  
  // Get color hex by name
  export const getColorHex = (colorName, finishType = 'gloss') => {
    const colorInfo = getColorInfo(colorName, finishType);
    return colorInfo ? colorInfo.color : null;
  };
  
  // Get all colors for a finish
  export const getColorsForFinish = (finishType = 'gloss') => {
    return colorLibrary[finishType] || [];
  };
  
  // Get available finishes
  export const getAvailableFinishes = () => {
    return Object.keys(colorLibrary);
  };