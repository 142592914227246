import React, { useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import HelpIcon from '@mui/icons-material/Help';

// Styled components
const LogContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
}));

const LogHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.grey[100],
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const LogContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
}));

// Use shouldForwardProp to prevent custom props from being passed to DOM
const LogEntry = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'type'
})(({ theme, type = 'info' }) => {
  // Define colors based on log type
  const getBackgroundColor = () => {
    switch (type) {
      case 'success':
        return theme.palette.success.light;
      case 'error':
        return theme.palette.error.light;
      case 'warning':
        return theme.palette.warning.light;
      case 'info':
      default:
        return theme.palette.info.light;
    }
  };
  
  const getBorderColor = () => {
    switch (type) {
      case 'success':
        return theme.palette.success.main;
      case 'error':
        return theme.palette.error.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'info':
      default:
        return theme.palette.info.main;
    }
  };
  
  return {
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    backgroundColor: alpha(getBackgroundColor(), 0.15),
    borderRadius: theme.shape.borderRadius,
    borderLeft: `4px solid ${getBorderColor()}`,
    '&:last-child': {
      marginBottom: 0,
    },
  };
});

const LogTimestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 'medium',
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
}));

const LogMessage = styled(Box)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
  color: theme.palette.text.primary,
  fontFamily: 'monospace',
  fontSize: '0.875rem',
}));

const EmptyLogMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  color: theme.palette.text.secondary,
  padding: theme.spacing(3),
}));

// Improved helper function to create an alpha value of a color
function alpha(color, value) {
  // Handle rgba format
  if (typeof color === 'string' && color.startsWith('rgba')) {
    return color.replace(/rgba\(([^,]+),([^,]+),([^,]+),[^)]+\)/, 
      (_, r, g, b) => `rgba(${r},${g},${b},${value})`);
  }
  
  // Handle rgb format
  if (typeof color === 'string' && color.startsWith('rgb')) {
    return color.replace(/rgb\(([^,]+),([^,]+),([^,]+)\)/, 
      (_, r, g, b) => `rgba(${r},${g},${b},${value})`);
  }
  
  // Handle hex format
  if (typeof color === 'string' && color.startsWith('#')) {
    return `${color}${Math.round(value * 255)
      .toString(16)
      .padStart(2, '0')}`;
  }
  
  // Fallback for theme colors or named colors
  return `${color}${Math.round(value * 255).toString(16).padStart(2, '0')}`;
}

/**
 * API Log component for displaying communication logs
 * 
 * @param {Object} props
 * @param {Array} props.logEntries - Array of log entries with type, timestamp, and message
 */
const APILog = ({ logEntries = [] }) => {
  const theme = useTheme();
  const logContentRef = useRef(null);
  const isFirstRender = useRef(true);

  // Auto-scroll to bottom when new logs are added
  useEffect(() => {
    if (logContentRef.current && !isFirstRender.current) {
      // Smooth scroll to bottom
      logContentRef.current.scrollTo({
        top: logContentRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
    isFirstRender.current = false;
  }, [logEntries]);

  // Function to copy logs to clipboard
  const copyLogsToClipboard = () => {
    const logText = logEntries
      .map(entry => `[${entry.timestamp}] ${entry.type.toUpperCase()}: ${entry.message}`)
      .join('\n');
    
    navigator.clipboard.writeText(logText).catch(err => {
      console.error('Failed to copy logs to clipboard:', err);
    });
  };

  // Scroll to bottom button handler
  const scrollToBottom = () => {
    if (logContentRef.current) {
      logContentRef.current.scrollTo({
        top: logContentRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  // Get icon based on log type
  const getLogIcon = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon fontSize="small" color="success" />;
      case 'error':
        return <ErrorIcon fontSize="small" color="error" />;
      case 'warning':
        return <WarningIcon fontSize="small" color="warning" />;
      case 'info':
      default:
        return <InfoIcon fontSize="small" color="info" />;
    }
  };

  return (
    <LogContainer>
      <LogHeader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InfoIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
          <Typography variant="subtitle2">API Communication Log</Typography>
          
          <Tooltip title="This log shows the communication between the app and the Stability AI API">
            <IconButton size="small" sx={{ ml: 0.5 }}>
              <HelpIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        
        <Box>
          <Tooltip title="Copy logs to clipboard">
            <span>
              <IconButton 
                size="small" 
                onClick={copyLogsToClipboard}
                disabled={logEntries.length === 0}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          
          <Tooltip title="Scroll to bottom">
            <span>
              <IconButton 
                size="small" 
                onClick={scrollToBottom}
                disabled={logEntries.length === 0}
              >
                <ArrowCircleDownIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </LogHeader>
      
      <LogContent ref={logContentRef}>
        {logEntries.length === 0 ? (
          <EmptyLogMessage>
            <InfoIcon sx={{ fontSize: 40, mb: 2, opacity: 0.5 }} />
            <Typography variant="body2" align="center">
              API logs will appear here when you process an image.
            </Typography>
            <Typography variant="caption" color="text.secondary" align="center" sx={{ mt: 1 }}>
              These logs can help troubleshoot issues if your color change doesn't work as expected.
            </Typography>
          </EmptyLogMessage>
        ) : (
          logEntries.map((entry, index) => (
            <Fade key={index} in={true} timeout={300}>
              <LogEntry type={entry.type}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ mr: 1.5, mt: 0.2 }}>{getLogIcon(entry.type)}</Box>
                  <Box sx={{ flex: 1 }}>
                    <LogTimestamp>{entry.timestamp}</LogTimestamp>
                    <LogMessage>
                      {entry.message.split('\n').map((line, i) => (
                        <div key={i}>{line || ' '}</div>
                      ))}
                    </LogMessage>
                  </Box>
                </Box>
              </LogEntry>
            </Fade>
          ))
        )}
      </LogContent>
    </LogContainer>
  );
};

export default APILog;