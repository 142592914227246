import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  IconButton, 
  Tooltip, 
  Skeleton,
  Paper,
  Fade,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import CompareIcon from '@mui/icons-material/Compare';

// Styled components
const ImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  overflow: 'hidden',
}));

const ImageViewport = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'auto',
  position: 'relative',
}));

const StyledImage = styled('img')(({ theme, zoom = 100 }) => ({
  maxWidth: `${zoom}%`,
  maxHeight: `${zoom}%`,
  objectFit: 'contain',
  transition: 'max-width 0.3s, max-height 0.3s',
}));

const EmptyMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(3),
  color: theme.palette.text.secondary,
}));

const ControlsPanel = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  boxShadow: theme.shadows[2],
  zIndex: 2,
  transition: 'opacity 0.3s ease',
}));

/**
 * Image viewer component with zoom and fullscreen capabilities
 * 
 * @param {Object} props
 * @param {string} props.image - Image URL or data URL
 * @param {string} props.title - Title of the image
 * @param {string} props.emptyMessage - Message to display when no image is available
 * @param {boolean} props.showControls - Whether to show zoom controls
 * @param {function} props.onSave - Optional callback when save button is clicked
 */
const ResultViewer = ({ 
  image, 
  title, 
  emptyMessage, 
  showControls: initialShowControls = true,
  onSave
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [loading, setLoading] = useState(true);
  const [zoom, setZoom] = useState(100);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [error, setError] = useState(false);
  
  const containerRef = useRef(null);
  const imageRef = useRef(null);

  // Reset loading state when image changes
  useEffect(() => {
    if (image) {
      setLoading(true);
      setError(false);
    } else {
      setLoading(false);
    }
  }, [image]);

  // Handle image load
  const handleImageLoad = () => {
    setLoading(false);
  };

  // Handle image error
  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  // Zoom controls
  const handleZoomIn = () => {
    setZoom(prevZoom => Math.min(prevZoom + 25, 400));
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => Math.max(prevZoom - 25, 25));
  };

  const handleZoomReset = () => {
    setZoom(100);
  };

  // Toggle fullscreen view
  const toggleFullscreen = () => {
    setShowFullscreen(!showFullscreen);
    setZoom(100); // Reset zoom when toggling fullscreen
  };
  
  // Mouse events for showing/hiding controls
  const handleMouseEnter = () => {
    setShowControls(true);
  };
  
  const handleMouseLeave = () => {
    setShowControls(false);
  };
  
  // Download the image
  const handleDownload = () => {
    if (!image) return;
    
    const link = document.createElement('a');
    link.href = image;
    link.download = `car-color-changer-${Date.now()}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // If no image is provided, show the empty message
  if (!image) {
    return (
      <ImageContainer>
        <EmptyMessage>
          <PhotoLibraryIcon sx={{ fontSize: 48, color: 'text.disabled', mb: 2 }} />
          <Typography variant="body1" color="text.secondary" align="center">
            {emptyMessage || 'No image available'}
          </Typography>
        </EmptyMessage>
      </ImageContainer>
    );
  }

  // Fullscreen dialog
  if (showFullscreen) {
    return (
      <Dialog
        open={showFullscreen}
        onClose={toggleFullscreen}
        maxWidth="xl"
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.common.white,
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          bgcolor: 'rgba(0,0,0,0.7)'
        }}>
          <Typography variant="h6">{title || 'Image Viewer'}</Typography>
          <Box>
            <Tooltip title="Download">
              <IconButton color="inherit" onClick={handleDownload}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Exit Fullscreen">
              <IconButton color="inherit" onClick={toggleFullscreen}>
                <FullscreenExitIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        
        <DialogContent sx={{ p: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ position: 'relative', width: '100%', height: '100%', display: 'flex' }}>
            <ImageViewport>
              {loading && (
                <Skeleton 
                  variant="rectangular" 
                  width="80%" 
                  height="80%" 
                  animation="wave" 
                />
              )}
              <StyledImage
                ref={imageRef}
                src={image}
                alt={title || 'Image'}
                zoom={zoom}
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{ display: loading ? 'none' : 'block' }}
              />
            </ImageViewport>
            
            <ControlsPanel sx={{ 
              bottom: 20, 
              right: 20, 
              p: 1, 
              bgcolor: 'rgba(0,0,0,0.7)',
              border: '1px solid rgba(255,255,255,0.2)',
            }}>
              <Tooltip title="Zoom Out">
                <IconButton size="medium" onClick={handleZoomOut} color="inherit">
                  <ZoomOutIcon />
                </IconButton>
              </Tooltip>
              
              <Button
                size="small"
                onClick={handleZoomReset}
                sx={{ minWidth: 'auto', px: 1.5, color: 'white' }}
              >
                {zoom}%
              </Button>
              
              <Tooltip title="Zoom In">
                <IconButton size="medium" onClick={handleZoomIn} color="inherit">
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            </ControlsPanel>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  // Regular view
  return (
    <ImageContainer
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {loading && (
        <Skeleton
          variant="rectangular"
          width="80%"
          height="80%"
          animation="wave"
          sx={{ borderRadius: 1 }}
        />
      )}
      
      {error && (
        <EmptyMessage>
          <Typography variant="body1" color="error">
            Failed to load image
          </Typography>
        </EmptyMessage>
      )}
      
      <ImageViewport>
        <StyledImage
          ref={imageRef}
          src={image}
          alt={title || 'Image'}
          zoom={zoom}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{ display: loading || error ? 'none' : 'block' }}
        />
      </ImageViewport>
      
      {initialShowControls && showControls && !loading && !error && (
        <Fade in={showControls}>
          <ControlsPanel>
            <Tooltip title="Zoom Out">
              <IconButton size="small" onClick={handleZoomOut}>
                <ZoomOutIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="Reset Zoom">
              <Button
                variant="text"
                size="small"
                onClick={handleZoomReset}
                sx={{ minWidth: 'auto', px: 1 }}
              >
                {zoom}%
              </Button>
            </Tooltip>
            
            <Tooltip title="Zoom In">
              <IconButton size="small" onClick={handleZoomIn}>
                <ZoomInIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="Fullscreen">
              <IconButton size="small" onClick={toggleFullscreen}>
                <FullscreenIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            
            {onSave && (
              <Tooltip title="Save Image">
                <IconButton size="small" onClick={onSave}>
                  <SaveIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            
            <Tooltip title="Download">
              <IconButton size="small" onClick={handleDownload}>
                <DownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </ControlsPanel>
        </Fade>
      )}
    </ImageContainer>
  );
};

export default ResultViewer;