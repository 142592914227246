import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CircularProgress, Box, Typography } from '@mui/material';
import { useAuth } from './AuthContext';

/**
 * Protected Route component that handles authentication and role-based access control
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.children - The components to render if access is allowed
 * @param {boolean} props.requiresPaid - Whether this route requires a paid subscription
 */
const ProtectedRoute = ({ children, requiresPaid = false }) => {
  const { currentUser, userProfile, loading } = useAuth();
  const location = useLocation();

  // Show loading state while authentication state is being determined
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          gap: 2,
        }}
      >
        <CircularProgress size={40} />
        <Typography variant="body1" color="text.secondary">
          Loading authentication status...
        </Typography>
      </Box>
    );
  }

  // Redirect to login if user is not authenticated
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Check if route requires paid subscription
  if (requiresPaid && userProfile?.role !== 'Paid') {
    // Redirect to pricing page with a message
    return <Navigate to="/pricing" state={{ 
      from: location,
      message: "This feature requires a paid subscription."
    }} />;
  }

  // If all checks pass, render the protected component
  return children;
};

export default ProtectedRoute;