import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import NavigationBar from '../layout/NavigationBar';

const PricingPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleSignup = () => {
    if (!currentUser) {
      navigate('/login');
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMonthly = () => {
    window.location.href = 'https://buy.stripe.com/4gw3ds2p30Lvdji145';
  };

  const handleYearly = () => {
    window.location.href = 'https://buy.stripe.com/fZe7tI0gVeClgvudQQ';
  };

  // Common styles
  const checkItemStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    marginBottom: '10px',
  };

  const headerStyle = {
    color: '#1976d2',
    fontWeight: 'bold',
    marginBottom: '6px',
    marginTop: '16px',
    fontSize: '0.9rem',
    display: 'block',
  };

  return (
    <>
      <NavigationBar />
      
      <Box sx={{ py: 6, backgroundColor: '#f5f5f5', minHeight: 'calc(100vh - 64px)' }}>
        <Container maxWidth="lg">
          {/* Page Title */}
          <Typography 
            variant="h2" 
            align="center" 
            sx={{ 
              fontWeight: 700, 
              mb: 5,
              color: '#333'
            }}
          >
            Our Pricing Plans
          </Typography>
          
          {/* Feature Chips */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3, gap: 1 }}>
            <Chip
              icon={<ColorLensIcon />}
              label="AutoWrap"
              sx={{
                bgcolor: '#1976d2',
                color: 'white',
                fontSize: '0.9rem',
                px: 2,
                py: 2.5,
                '& .MuiChip-icon': { color: 'white' }
              }}
            />
            <Chip
              icon={<ThreeDRotationIcon />}
              label="3D Configurator"
              sx={{
                bgcolor: '#e91e63',
                color: 'white',
                fontSize: '0.9rem',
                px: 2,
                py: 2.5,
                '& .MuiChip-icon': { color: 'white' }
              }}
            />
          </Box>
          
          {/* Subtitle */}
          <Typography 
            variant="h5" 
            align="center" 
            sx={{ mb: 1 }}
          >
            Transform Your Car's Appearance with Advanced Tools
          </Typography>
          
          <Typography 
            variant="body1" 
            align="center" 
            sx={{ 
              mb: 5, 
              maxWidth: '850px', 
              mx: 'auto',
              color: '#555'
            }}
          >
            Our platform offers both AutoWrap for realistic car recoloring from your own photos and a 3D Configurator to explore different car models and colors in 3D space.
          </Typography>
          
          {/* Pricing Cards */}
          <Grid container spacing={3} justifyContent="center">
            {/* Free Plan */}
            <Grid item xs={12} sm={6} md={4}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
                  borderRadius: 2,
                  position: 'relative',
                  overflow: 'visible'
                }}
              >
                <CardContent sx={{ p: 3, pb: 1 }}>
                  <Typography 
                    variant="h5" 
                    color="primary" 
                    align="center"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    Free
                  </Typography>
                  <Typography 
                    variant="h6" 
                    align="center"
                    sx={{ fontWeight: 'bold', mb: 3 }}
                  >
                    $0/month
                  </Typography>
                  
                  <Typography sx={headerStyle}>
                    3D Configurator:
                  </Typography>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Access to 1 car model</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Limited color palette</Typography>
                  </Box>
                  
                  <Typography sx={headerStyle}>
                    AutoWrap:
                  </Typography>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">5 car color generations per month</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Basic body recoloring</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">No watermarks</Typography>
                  </Box>
                </CardContent>
                
                <CardActions sx={{ p: 3, pt: 0, mt: 'auto' }}>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    component={Link} 
                    to="/demo"
                    fullWidth
                    sx={{ borderRadius: 1 }}
                  >
                    Try the Demo
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            
            {/* Standard Plan */}
            <Grid item xs={12} sm={6} md={4}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#e7f3fe',
                  boxShadow: '0 2px 20px rgba(0,0,0,0.1)',
                  border: '2px solid #1976d2',
                  borderRadius: 2,
                  position: 'relative',
                  overflow: 'visible',
                  zIndex: 1
                }}
              >
                {/* Most Popular Tag */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: -13,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bgcolor: '#1976d2',
                    color: 'white',
                    px: 2,
                    py: 0.5,
                    borderRadius: 5,
                    zIndex: 2,
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                  }}
                >
                  <StarIcon fontSize="small" />
                  MOST POPULAR
                </Box>
                
                <CardContent sx={{ p: 3, pb: 1 }}>
                  <Typography 
                    variant="h5" 
                    color="primary" 
                    align="center"
                    sx={{ fontWeight: 'bold', mb: 1, mt: 1 }}
                  >
                    Standard
                  </Typography>
                  <Typography 
                    variant="h6" 
                    align="center"
                    sx={{ fontWeight: 'bold', mb: 3 }}
                  >
                    $2.99/month or $29/year
                  </Typography>
                  
                  <Typography sx={headerStyle}>
                    3D Configurator:
                  </Typography>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Access to complete car library</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Full color palette</Typography>
                  </Box>
                  
                  <Typography sx={headerStyle}>
                    AutoWrap:
                  </Typography>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2"><strong>Unlimited</strong> car color generations</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Advanced finish options (gloss, matte, satin)</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">No watermarks</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="primary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Save and share results</Typography>
                  </Box>
                </CardContent>
                
                <CardActions sx={{ p: 3, pt: 0, mt: 'auto' }}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleSignup}
                    fullWidth
                    sx={{ borderRadius: 1 }}
                  >
                    Sign Up Now
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            
            {/* Custom Plan */}
            <Grid item xs={12} sm={6} md={4}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#fdf3f9',
                  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
                  border: '2px solid #e91e63',
                  borderRadius: 2,
                  position: 'relative'
                }}
              >
                <CardContent sx={{ p: 3, pb: 1 }}>
                  <Typography 
                    variant="h5" 
                    color="secondary" 
                    align="center"
                    sx={{ fontWeight: 'bold', mb: 1 }}
                  >
                    Custom
                  </Typography>
                  <Typography 
                    variant="h6" 
                    align="center"
                    sx={{ fontWeight: 'bold', mb: 3 }}
                  >
                    Contact for Pricing
                  </Typography>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="secondary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Everything in Standard plan</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="secondary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">API access for integration</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="secondary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Custom color profiles for your business</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="secondary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Dedicated account manager</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="secondary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">White label solution available</Typography>
                  </Box>
                  
                  <Box sx={checkItemStyle}>
                    <CheckCircleIcon color="secondary" sx={{ mt: '2px', fontSize: '1.1rem' }} />
                    <Typography variant="body2">Priority feature requests</Typography>
                  </Box>
                </CardContent>
                
                <CardActions sx={{ p: 3, pt: 0, mt: 'auto' }}>
                  <Button 
                    variant="outlined" 
                    color="secondary" 
                    href="mailto:support@reignovation.com"
                    fullWidth
                    sx={{ borderRadius: 1 }}
                  >
                    Contact Us
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          
          {/* FAQ Section */}
          <Box sx={{ mt: 8 }}>
            <Typography 
              variant="h4" 
              align="center" 
              sx={{ 
                fontWeight: 'bold', 
                mb: 4,
                color: '#333'
              }}
            >
              Frequently Asked Questions
            </Typography>
            
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: '600' }}>
                  What's the difference between AutoWrap and the 3D Configurator?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  AutoWrap lets you visualize color changes on photos of your actual car, while the 3D Configurator allows you to explore different car models and colors in a 3D environment.
                </Typography>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: '600' }}>
                  How many cars can I visualize with the free plan?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The free plan includes access to 1 car model in the 3D Configurator and 5 color generations per month with AutoWrap for your own car photos.
                </Typography>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: '600' }}>
                  What does "unlimited generations" mean?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Standard and Custom plan subscribers can create as many different color visualizations as they want without any monthly limits. You can experiment with different colors and finishes to your heart's content.
                </Typography>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ mb: 1, fontWeight: '600' }}>
                  Can I cancel my subscription anytime?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Yes, you can cancel your subscription at any time. Your benefits will continue until the end of your current billing period.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          
          {/* Dialog for Monthly/Yearly Plan Selection */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select Your Plan</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please select whether you'd like to sign up for the monthly or yearly subscription.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleMonthly} color="primary">
                Monthly ($2.99)
              </Button>
              <Button onClick={handleYearly} color="primary" variant="contained">
                Yearly ($29) - Save 20%
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
      
      {/* Footer Section */}
      <Box sx={{ backgroundColor: '#0d47a1', color: '#fff', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                About Us
              </Typography>
              <Typography variant="body2">
                Reignovation is a software consulting company specializing in
                creating innovative, custom solutions for businesses across
                industries. We help companies streamline operations and elevate
                their customer experiences with cutting-edge technology and
                tools.
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Link to="/features" style={{ color: '#fff', textDecoration: 'none' }}>
                  Features
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/pricing" style={{ color: '#fff', textDecoration: 'none' }}>
                  Pricing
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>
                  Contact Us
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link to="/demo" style={{ color: '#fff', textDecoration: 'none' }}>
                  Try the Demo
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email:{' '}
                <a href="mailto:support@reignovation.com" style={{ color: '#fff' }}>
                  support@reignovation.com
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 4 }}>
            © {new Date().getFullYear()} Reignovation. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default PricingPage;