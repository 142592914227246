import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  updateProfile,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc,
  increment,
  collection,
  addDoc,
  serverTimestamp
} from 'firebase/firestore';
import { 
  getStorage, 
  ref, 
  uploadBytes, 
  getDownloadURL,
  deleteObject
} from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNoyG5xT8uBBB-SJ9DhfW-tVE5D7qRJ6U",
  authDomain: "vae-website-show.firebaseapp.com",
  projectId: "vae-website-show",
  storageBucket: "vae-website-show.firebasestorage.app",
  messagingSenderId: "755340385876",
  appId: "1:755340385876:web:0ba24ea2e918ea6515d7c1",
  measurementId: "G-YS7DD8DPMB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

// Google Auth Provider
const googleProvider = new GoogleAuthProvider();

/**
 * Creates a new user with email and password
 * @param {string} email 
 * @param {string} password 
 * @param {string} displayName 
 * @param {Object} additionalData 
 * @returns {Promise<Object>} User object
 */
export const signupWithEmail = async (email, password, displayName, additionalData = {}) => {
  try {
    // Create the user in Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Update the user's display name
    await updateProfile(user, { displayName });

    // Create the user document in Firestore
    await setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      email: user.email,
      displayName,
      role: 'User', // Default role
      ...additionalData,
      createdAt: serverTimestamp(),
      lastLogin: serverTimestamp()
    });

    return { user, success: true };
  } catch (error) {
    console.error("Signup error:", error);
    return { error: error.message, success: false };
  }
};

/**
 * Signs in a user with email and password
 * @param {string} email 
 * @param {string} password 
 * @returns {Promise<Object>} Auth result
 */
export const loginWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Update last login time
    await updateDoc(doc(db, 'users', user.uid), {
      lastLogin: serverTimestamp()
    });

    return { user, success: true };
  } catch (error) {
    console.error("Login error:", error);
    return { error: error.message, success: false };
  }
};

/**
 * Signs in a user with Google
 * @returns {Promise<Object>} Auth result
 */
export const loginWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    // Check if user exists in Firestore, if not create a new document
    const userDoc = doc(db, 'users', user.uid);
    const docSnap = await getDoc(userDoc);

    if (!docSnap.exists()) {
      // Extract first and last name from display name
      const displayNameParts = user.displayName ? user.displayName.split(' ') : ['User', ''];
      const firstName = displayNameParts[0];
      const lastName = displayNameParts.length > 1 ? displayNameParts.slice(1).join(' ') : '';

      // Create a new user document
      await setDoc(userDoc, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        firstName,
        lastName,
        role: 'User', // Default role
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp()
      });
    } else {
      // Update last login time
      await updateDoc(userDoc, {
        lastLogin: serverTimestamp()
      });
    }

    return { user, success: true };
  } catch (error) {
    console.error("Google Sign-In error:", error);
    return { error: error.message, success: false };
  }
};

/**
 * Signs out the current user
 * @returns {Promise<Object>} Result
 */
export const logoutUser = async () => {
  try {
    await signOut(auth);
    return { success: true };
  } catch (error) {
    console.error("Logout error:", error);
    return { error: error.message, success: false };
  }
};

/**
 * Sends a password reset email
 * @param {string} email 
 * @returns {Promise<Object>} Result
 */
export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true };
  } catch (error) {
    console.error("Password reset error:", error);
    return { error: error.message, success: false };
  }
};

/**
 * Fetches user profile from Firestore
 * @param {string} uid User ID
 * @returns {Promise<Object>} User profile
 */
export const fetchUserProfile = async (uid) => {
  try {
    if (!uid) return null;
    
    const userDoc = doc(db, 'users', uid);
    const userSnap = await getDoc(userDoc);
    
    if (userSnap.exists()) {
      return userSnap.data();
    }
    
    return null;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

/**
 * Updates user profile in Firestore
 * @param {string} uid User ID
 * @param {Object} updates Fields to update
 * @returns {Promise<Object>} Result
 */
export const updateUserProfile = async (uid, updates) => {
  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, {
      ...updates,
      updatedAt: serverTimestamp()
    });
    return { success: true };
  } catch (error) {
    console.error("Profile update error:", error);
    return { error: error.message, success: false };
  }
};

// Car Color Changer specific functions

/**
 * Checks if user can generate a new car color
 * @param {string} uid User ID
 * @returns {Promise<Object>} Usage check result
 */
export const checkCarColorChangerUsage = async (uid) => {
  try {
    const validateUsage = httpsCallable(functions, 'validateCarColorChangerUsage');
    const result = await validateUsage();
    return result.data;
  } catch (error) {
    console.error("Error checking usage:", error);
    return { 
      allowed: false, 
      reason: 'error', 
      message: 'Failed to check usage limits. Please try again later.'
    };
  }
};

/**
 * Records a car color change in Firestore
 * @param {string} uid User ID
 * @param {Object} imageData Image data to record
 * @returns {Promise<Object>} Result
 */
export const recordCarColorChange = async (uid, imageData) => {
  try {
    // Update usage counter
    const statsRef = doc(db, 'carColorChangerStats', uid);
    const statsDoc = await getDoc(statsRef);
    
    if (statsDoc.exists()) {
      await updateDoc(statsRef, {
        used: increment(1),
        lastUsed: serverTimestamp()
      });
    } else {
      // Create stats document if it doesn't exist
      await setDoc(statsRef, {
        used: 1,
        limit: 5, // Default limit for free users
        lastUsed: serverTimestamp(),
        lastReset: serverTimestamp()
      });
    }
    
    // Record in history
    const historyRef = collection(db, 'carColorChangerHistory');
    await addDoc(historyRef, {
      userId: uid,
      timestamp: serverTimestamp(),
      ...imageData
    });
    
    return { success: true };
  } catch (error) {
    console.error("Error recording car color change:", error);
    return { error: error.message, success: false };
  }
};

/**
 * Fetches a user's car color changer stats
 * @param {string} uid User ID
 * @returns {Promise<Object>} Stats object
 */
export const fetchCarColorChangerStats = async (uid) => {
  try {
    const statsRef = doc(db, 'carColorChangerStats', uid);
    const statsDoc = await getDoc(statsRef);
    
    if (statsDoc.exists()) {
      return statsDoc.data();
    }
    
    // Return default stats if no document exists
    return { used: 0, limit: 5, lastReset: new Date().toISOString() };
  } catch (error) {
    console.error("Error fetching car color changer stats:", error);
    throw error;
  }
};

/**
 * Uploads a file to Firebase Storage
 * @param {File} file File to upload
 * @param {string} path Path in storage
 * @param {Object} metadata Optional metadata
 * @returns {Promise<string>} Download URL
 */
export const uploadFile = async (file, path, metadata = {}) => {
  try {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file, { customMetadata: metadata });
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("File upload error:", error);
    throw error;
  }
};

/**
 * Deletes a file from Firebase Storage
 * @param {string} path Path in storage
 * @returns {Promise<boolean>} Success status
 */
export const deleteFile = async (path) => {
  try {
    const storageRef = ref(storage, path);
    await deleteObject(storageRef);
    return true;
  } catch (error) {
    console.error("File deletion error:", error);
    return false;
  }
};

// Configurator specific functions

/**
 * Fetches user configuration from Firestore
 * @param {string} uid User ID
 * @returns {Promise<Object>} Configuration object
 */
export const fetchUserConfiguration = async (uid) => {
  try {
    const configRef = doc(db, 'configurations', uid);
    const configSnap = await getDoc(configRef);
    
    if (configSnap.exists()) {
      return configSnap.data();
    }
    
    return null;
  } catch (error) {
    console.error("Error fetching user configuration:", error);
    throw error;
  }
};

/**
 * Updates user configuration in Firestore
 * @param {string} uid User ID
 * @param {Object} config Configuration to update
 * @returns {Promise<boolean>} Success status
 */
export const updateUserConfiguration = async (uid, config) => {
  try {
    const configRef = doc(db, 'configurations', uid);
    await setDoc(configRef, config, { merge: true });
    return true;
  } catch (error) {
    console.error("Error updating user configuration:", error);
    return false;
  }
};